import React from 'react';
import styled, { keyframes } from 'styled-components'

const pointer = keyframes`
  0% {transform: rotate(0deg)};
  50% {transform: rotate(1deg)};
  100% {transform: rotate(0deg)};
`

const heartbeat = keyframes`
  0% { fill: #467DE4; }
  50% { fill: #2B337C; }
  100% { fill: #467DE4;}
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    svg {
        enable-background:new 0 0 807.7 630.6;
        max-width : 50vw; 
        max-height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        path {
            &.st0{
                fill:#FFFFFF;
            }
            &.st1 {
                fill: #353559;
            }
            &.st2 {
                fill: #86A3BF;
            }
            &.st3 {
                fill: #A8C4E5;
                opacity: 0.4;
            }
            &.st4 {
                fill: #2B337C;
            }
            &.pointer {
                transform-origin: center center;
                fill: #2B337C;
                animation: ${pointer} 3s ease-in-out infinite;
            }
            &.st5 {
                fill: #6CA4FF;
            }
            &.st6 {
                fill: #A8C4E5;
            }
            &.st7 {
                fill: #366BC1;
            }
            &.st8 {
                fill: #E6E6ED;
            }
            &.st9 {
                fill: #FFAA82;
            }
            &.st10 {
                fill: #3165AF;
            }
            &.st11 {
                fill: #FCFEFF;
            }
            &.st12 {
                fill: #EA8A6C;
            }
            &.st13 {
                fill: #FBFBFF;
            }
            &.st14 {
                fill: #492817;
            }
            &.st15 {
                fill: #FFFFFF;
                opacity: 0.46;
            }
            &.st16 {
                fill: #513554;
            }
            &.st17 {
                fill: #E1E8EF;
            }
            &.st18 {
                fill: #D3DDE5;
            }
            &.st19 {
                fill: #3D4993;
            }
            &.st20 {
                fill: #467DE4;
            }
            &.heart {
                fill: #467DE4;
                animation: ${heartbeat} 1.5s ease-in-out infinite;
            }
            &.st21 {
                fill: #1E345B;
            }
            &.st22 {
                fill: #E9E8E6;
            }
            &.st23 {
                fill: #BBD6F2;
            }
            &.st24 {
                fill: #5366C4;
            }
            &.st25 {
                fill: #97CBFF;
            }
            &.st26 {
                fill: #F84610;
            }
            &.st27 {
                fill: #F0F6FC;
            }
            &.st28 {
                fill: #E1EAF4;
            }
            &.st29 {
                fill: #EA8E6A;
            }
            &.st30 {
                fill: #6B5953;
            }
            &.st31 {
                fill: #915151;
            }
            &.st32 {
                fill: #DB360F;
            }
            &.st33 {
                fill: #A0E2E2;
            }
            &.st34 {
                fill: #D2D2D8;
            }
            &.st35 {
                fill: #EF9A78;
            }
            &.st36 {
                fill: #424268;
            }
            &.st37 {
                fill: #F1A32F;
            }
            &.st38 {
                fill: #633820;
            }
            &.st39 {
                fill: #AF8080;
            }
        }
        ellipse {
            &.st0{
                fill:#FFFFFF;
            }
            &.st1 {
                fill: #353559;
            }
            &.st3 {
                fill: #A8C4E5;
                opacity: 0.4;
            }
            &.st6 {
                fill: #A8C4E5;
            }
            &.st14 {
                fill: #492817;
            }
            &.st17 {
                fill: #E1E8EF;
            }
            &.st26 {
                fill: #F84610;
            }
        }
        polygon {
            &.st0{
                fill:#FFFFFF;
            }
            &.st1 {
                fill: #353559;
            }
            &.st2 {
                fill: #86A3BF;
            }
            &.st11 {
                fill: #FCFEFF;
            }
            &.st17 {
                fill: #E1E8EF;
            }
            &.st21 {
                fill: #1E345B;
            }
            &.st28 {
                fill: #E1EAF4;
            }
        }
    }
`


const HomeIllustration = () => {
    return (
        <Container>
            <svg x="0px" y="0px" viewBox="0 0 807.7 630.6">
                <g id="background">
                </g>
                <g id="image">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path className="st0" d="M191.7,60.8c0.2,16.8-3.8,33.7,1,50.3c1.8,6.4,5.6,11.5,12.2,13.3c6.3,1.7,12.6,1.6,18.7,4.4
                                        c7.9,3.6,14.7-8.1,6.8-11.7c-6.2-2.8-12.2-4.1-18.8-5.1c-1.1-0.2-2.3-0.4-3.4-0.7c-0.1,0-1.3-0.5-0.7-0.2
                                        c0.6,0.3-0.7-0.7,0.1,0.1c-1.3-1.3-1.9-3.4-2.5-5.9c-3.2-14.7,0.3-29.6,0.1-44.5C205.1,52.1,191.6,52.1,191.7,60.8L191.7,60.8z
                                        "/>
                                </g>
                            </g>
                            <path className="st1" d="M208.1,137.7L182,119c-2.8-2-3.5-6-1.5-8.8l25.8-35.9c2-2.8,6-3.5,8.8-1.5l26.1,18.7c2.8,2,3.5,6,1.5,8.8
                                l-25.8,35.9C214.9,139.1,210.9,139.7,208.1,137.7z"/>
                            <polygon className="st2" points="195.2,183.6 158.3,197.3 158.3,135.3 195.2,121.6 			"/>
                            
                                <ellipse transform="matrix(0.9396 -0.3424 0.3424 0.9396 -85.7674 78.2752)" className="st3" cx="178.8" cy="282.1" rx="48.5" ry="22.4"/>
                            <path className="st0" d="M201.3,49c4.5,7.1,4.7,10.9,3.7,24.7c0.8,2.8-3.1,13.1-6,13.3l-6.4,0.7c-2.3,0.2-4.5-4.8-4.8-7.2l-4-26.3
                                c-0.7-4.3,1.9-8.4,6-9.5l0,0C194,43.6,197.8,44,201.3,49z"/>
                            <path className="st4" d="M160.8,58.1l24.6-6.1l-3.6-12.1c-7.4-0.1-12.6,2.2-18.9,6.5L160.8,58.1z"/>
                            <path className="st5" d="M149.7,292.2c-0.2-0.4-2.5-5.4-3-8.4c-0.1-0.6-0.4-3.2-0.3-4.1c0.1-2-0.2-3.2,4.3-6
                                c7.3-4.5,14.2,20.3,14.2,20.3l0.5,6c0.2,1.1-0.7,2.2-1.6,2.9l-0.5,0.2c-3.6,0.9-5.7,0-7.2-1.7
                                C153.5,298.4,152.3,298.1,149.7,292.2z"/>
                            <path className="st1" d="M149.9,290.5c-0.2-0.4-6.9-15.2-0.3-17.9c5.8-2.3,8.7,3.4,11,9.5c0.7,1.8,2.4,5.6,3,6.9
                                c0.5,0.9,1.1,3.9,1.3,5l0,4.8c0.2,1.1-1.2,0.1-2.2,0.7l-0.8-4.2c-1.9,1.2-3.4,6.4-5,4.7C154.2,297,152.5,296.5,149.9,290.5z"/>
                            <g>
                                <g>
                                    <path className="st6" d="M146.8,148.6c7.1,21.8,5.6,45.9,3.7,68.4c-0.9,10.6-2.2,21.2-3.5,31.7c-0.8,6.9-2,13.9-1.9,20.9
                                        c0.1,10.3,16.1,10.3,16,0c-0.1-7,1.1-14,1.9-20.9c1.3-11,2.7-22,3.6-33.1c2-23.4,3-48.6-4.4-71.3
                                        C159.1,134.5,143.6,138.7,146.8,148.6L146.8,148.6z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st6" d="M181.8,145c3.6,13,5.2,26.4,6.5,39.8c0.8,7.8,1.8,15.3,1,23.1c-1.6,15.7-4.4,31.6-4.3,47.3
                                        c0.1,10.3,16.1,10.3,16,0c-0.1-16,2.8-32.2,4.4-48.1c0.8-8.3-0.5-16.6-1.3-24.9c-1.4-13.9-3.1-27.9-6.8-41.5
                                        C194.6,130.8,179.1,135,181.8,145L181.8,145z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st1" d="M159.4,269.7c-3.4,2.9-10.5,2.4-13.1-1.4c-0.1,2.1-0.1,4.1-0.1,6.2c0.1,8.4,13.2,8.6,13.1,0.2
                                        C159.4,273,159.4,271.4,159.4,269.7z"/>
                                </g>
                            </g>
                            <g>
                                <path className="st6" d="M185.6,166.1L185.6,166.1c-1.5,1.7,4.9-6.4,3-5.3c-4,2.4-26.2,17.5-30.8,17.6c-5.6,0.1-2.3-5.3-6.5-9
                                    c-3.9-3.5-4.9-9.3-5.3-14.6c-0.1-1.5-0.2-3,0.4-4.3c0.6-1.5,1.9-2.5,3.2-3.4c9.9-7.5,29.3-26,43.1-20.1
                                    C208.6,133.8,192.6,158.2,185.6,166.1z"/>
                            </g>
                            <path className="st7" d="M197.2,47.8c2.9,3.8,2.6,6.1,2.4,11.3c-0.1,5-3.5,9.4-8.4,10.8l-49.5,13.9c0,0-2.8-19.1,6.9-24.8
                                c5.3-3.1,13.4-8,19.8-11.9C177.9,41.2,190.4,39,197.2,47.8z"/>
                            <path className="st7" d="M199.8,137.3c-1.5,1.9-3.3-51.5-0.4-85.4l-55.8,26.5c0.3,25.5,3.5,45.6,0.4,76.6L199.8,137.3z"/>
                            <path className="st7" d="M162.6,160.7c13.8-5,26.9-10.5,34.6-20c3.8-2.2,3.3-6-1.1-8.6h0c-4.4-2.5-11-2.8-14.8-0.6l-34.6,20
                                c-3.8,2.2-3.3,6,1.1,8.6l0,0C152.2,162.6,158.8,162.8,162.6,160.7z"/>
                            <path className="st8" d="M143.1,267.4l-0.1,5.8c0,0.2,0.1,0.5,0.2,0.7c0.8,1.2,4.1,5.4,10.4,4.6c5.5-0.7,8.6-2.3,9.8-3
                                c0.4-0.2,0.6-0.6,0.6-1.1l-0.2-5.9c0-0.9-1-1.4-1.8-1c-2.9,1.5-9.2,3.7-16.9-1C144.2,265.9,143.1,266.5,143.1,267.4z"/>
                            <g>
                                <path className="st5" d="M200.4,276.6c-0.4-0.2-3.9-3-4.2-3.1c-0.5-0.2-5.5-2.7-6.3-3.1c-1.7-0.9-5.8-1.5-3.7-6.9
                                    c3-7.9,24,4.6,24,4.6l7,5.4c0.9,0.7,1.2,1.7,0.8,2.7l0,0c-0.6,1.9-3.2,3.3-5.5,3.1C208.5,278.7,206.4,279.2,200.4,276.6z"/>
                                <path className="st1" d="M200.7,274.6c-0.4-0.2-3.9-3-4.2-3.1c-0.5-0.2-6.6-2.7-7.4-3.1c-1.7-0.9-5.6-2-3.6-7.4c3-7.9,25,5,25,5
                                    l7,5.4c0.9,0.7,1.2,1.7,0.8,2.7l0,0c-0.6,1.9-3.2,3.3-5.5,3.1C208.8,276.7,206.7,277.2,200.7,274.6z"/>
                                <path className="st1" d="M208.8,276.9c1.1,0.1,3,0.1,4.3,0.3c2.3,0.3,4.7-1.2,5.3-3.1c0.3-1,0-2-0.8-2.7l-3.4-2.7
                                    C211.3,269.4,209.6,272.3,208.8,276.9z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st1" d="M199,253.5c-3.4,2.6-10.4,2.3-13.1-1.1c0,1.8-0.1,3.7,0,5.5c0.2,7.5,13.3,7.5,13.1,0
                                        C199,256.4,199,254.9,199,253.5z"/>
                                </g>
                            </g>
                            <path className="st8" d="M182.5,251.1l0.1,5.8c0,0.2,0.1,0.5,0.2,0.7c0.9,1.2,4.3,5.2,10.6,4c5.5-1,8.5-2.7,9.6-3.5
                                c0.4-0.3,0.5-0.7,0.5-1.1l-0.5-5.9c-0.1-0.9-1-1.4-1.8-0.9c-2.8,1.7-9,4.1-16.9-0.2C183.4,249.6,182.4,250.1,182.5,251.1z"/>
                            <path className="st9" d="M176.8,51.6l5.7-20.9l-14.4,4l-4.6,17.1C162.4,54.5,172.6,62.2,176.8,51.6z"/>
                            <path className="st10" d="M178.3,53.7c0,0-1.8,0.6-3.4,0.3l-5.7,9.7l0.3,0.3c0.9,1,2.6,0.9,3.2-0.2L178.3,53.7z"/>
                            <path className="st10" d="M172.4,55.6c0,0,1.7-0.1,2.8-1l9,5.9l-0.1,0.4c-0.3,1.2-1.7,1.7-2.7,1L172.4,55.6z"/>
                            <path className="st5" d="M173.6,54.2c0,0,6.1,0,7.2-10.6l4-0.6c0.6,0.4,1.7,0.1,2.3,3.7c0.3,2.1,0.2,4.2-0.3,6.3l-1.5,6
                                c-0.3,1.3-2,2-3.1,1.2L173.6,54.2z"/>
                            <path className="st11" d="M173.5,54.2c0,0,1.7-0.1,2.8-1l9,5.9l-0.1,0.4c-0.3,1.2-1.7,1.7-2.7,1L173.5,54.2z"/>
                            <path className="st5" d="M176.8,53.7c0,0-6.9,2.4-13.7-7.3l-4.5,5.6c-0.4,0.9-0.4,1,0.2,1.7l9.1,9.8c1.1,1.2,3,1,3.7-0.2L176.8,53.7z
                                "/>
                            <path className="st11" d="M176.9,53.7c0,0-1.8,0.6-3.4,0.3l-5.7,9.7l0.3,0.3c0.9,1,2.6,0.9,3.2-0.2L176.9,53.7z"/>
                            <path className="st12" d="M178.1,47.4c0,0-5.9-3-5.9-3.1c0,0,2,4.3,4.9,6L178.1,47.4z"/>
                            <path className="st7" d="M176.8,155c-4.5,2-9.3,3.9-14.1,5.7c-3.8,2.2-10.5,1.9-14.8-0.6v0c-2.5-1.4-3.7-3.3-3.6-5l-0.2,0
                                c3.1-31.1-0.2-51.2-0.4-76.6l1.9-0.9C146.6,107.1,151.4,153.8,176.8,155z"/>
                            <path className="st8" d="M159.2,51.1l7.8,11.6c3.3,4.1,4.7,20,4.6,25l2.4,119.1c0,0-34.5,1.8-33-14.8c4.3-47.8-5.8-122.3,2.5-130.3
                                C153.3,52.6,159.2,51.1,159.2,51.1z"/>
                            <g>
                                <g>
                                    <path className="st0" d="M141.7,65c-7.6,19.4-7.7,40.2-7.3,60.7c0.3,17.1,1.3,33.9,3.5,50.8c0.5,3.6,2.8,6.7,6.8,6.8
                                        c3.3,0,7.2-3.1,6.8-6.8c-2.1-16.3-3.2-32.5-3.5-49c-0.4-20-0.7-40,6.8-58.9C157.9,60.5,144.9,57,141.7,65L141.7,65z"/>
                                </g>
                            </g>
                            <path className="st9" d="M145.3,185.2L145.3,185.2c3.2-0.5,5.5-3.5,5-6.7l-1.6-11.2c-0.5-3.2-3.5-5.5-6.7-5l0,0
                                c-3.2,0.5-5.5,3.5-5,6.7l1.6,11.2C139.1,183.4,142.1,185.7,145.3,185.2z"/>
                            <path className="st8" d="M189.8,66.5l-4.3-20.4c0,0,3.3-5.6,10.4-1.6c10.8,6.1,3.6,82.2,3.9,95.5c0.3,13.3,9.5,34.4,7.7,50.1
                                c0,0-2.9,4.9-11.6,5.6c0,0,1.6-37.2-1.3-61.8C191.7,109.3,189.8,66.5,189.8,66.5z"/>
                            <path className="st0" d="M153.1,54.1c0,0,8.7,18.3,10.3,23.4l5.8,1.8l-1.8,7.4l4.6,21.9c0,0,4.7-36.2-10.4-60.6L153.1,54.1z"/>
                            <path className="st0" d="M195.1,44.1c0,0-0.1,19.5,0.1,24.9l-3.7,4.1l3.1,5.7l-2.8,22.3c0,0-2.6-30.3-6.9-60.5L195.1,44.1z"/>
                            <g>
                                <g>
                                    <path className="st1" d="M164.5,47.2c-2.1,1.8-3.9,4.1-3.4,7c0.4,2.3,1.7,4.3,2,6.6c0.7,4.6-0.6,9.2-0.7,13.8c0,1.4-2.2,1.3-2.1,0
                                        c0.2-6.1,1.8-11.7-0.5-17.6c-1.8-4.6-0.5-8.2,3.1-11.3C164,44.8,165.5,46.3,164.5,47.2L164.5,47.2z"/>
                                </g>
                                <g>
                                    <path className="st1" d="M162,56.4c2.8,5.2,9.6,8.4,9.2,15.2c-0.1,1.4-2.2,1.3-2.1,0c0.3-6.2-6.4-9.4-8.9-14.2
                                        C159.6,56.2,161.4,55.2,162,56.4L162,56.4z"/>
                                </g>
                                <path className="st5" d="M164,77l6.9,14.8c1.6,3.2,2.8,4.8,6.8,3.1c0.2,0.6,0.4,1.1,0.6,1.7l-0.6,0.3c-2.9,1.4-6.4,0.1-7.8-2.8
                                    l-8.2-17.8C160.3,73.4,162.7,74.1,164,77z"/>
                                <path className="st1" d="M179.5,94l-2.2,0.7c-0.6,0.2-0.9,0.9-0.7,1.5l0.1,0.3c0.2,0.6,0.9,0.9,1.5,0.7l2.2-0.7
                                    c0.6-0.2,0.9-0.9,0.7-1.5l-0.1-0.3C180.8,94.1,180.2,93.8,179.5,94z"/>
                                <path className="st5" d="M169.1,74.9l0,16.3c-0.1,3.5-0.5,5.5-4.9,5.6c0,0.6,0.1,1.2,0.2,1.8l0.7,0c3.2,0,5.9-2.6,5.9-5.8l0-19.6
                                    C171,70.1,169.1,71.7,169.1,74.9z"/>
                                <path className="st1" d="M162.2,96.9l2.3-0.3c0.6-0.1,1.2,0.4,1.3,1l0,0.3c0.1,0.6-0.4,1.2-1,1.3l-2.3,0.3c-0.6,0.1-1.2-0.4-1.3-1
                                    l0-0.3C161.1,97.5,161.5,96.9,162.2,96.9z"/>
                                <path className="st1" d="M162.7,76l-1.1,0.3c-0.6,0.2-1.2-0.2-1.4-0.8l-0.2-0.6c-0.2-0.6,0.2-1.2,0.8-1.4l1.1-0.3
                                    c0.6-0.2,1.2,0.2,1.4,0.8l0.2,0.6C163.6,75.2,163.3,75.8,162.7,76z"/>
                                <path className="st1" d="M170.6,73.8l-1.2,0.1c-0.6,0-1.1-0.4-1.2-1l0-0.6c0-0.6,0.4-1.1,1-1.2l1.2-0.1c0.6,0,1.1,0.4,1.2,1l0,0.6
                                    C171.7,73.2,171.3,73.7,170.6,73.8z"/>
                            </g>
                            <g>
                                <g>
                                    <g>
                                        <path className="st1" d="M189,79.9c-0.8-7.9-1-16-2.2-23.8c-0.8-5.2-2.4-11.3-8.3-12.5c-1.2-0.2-1-2.1,0.2-1.9
                                            c5.5,1.1,8,5.4,9.2,10.4c2.2,8.9,2.1,18.4,3,27.4C190.9,80.7,189.1,81.1,189,79.9L189,79.9z"/>
                                    </g>
                                </g>
                                <g>
                                    <path className="st5" d="M195.1,80.6c1,2.6-0.3,5.5-2.9,6.5c-2.6,1-5.5-0.3-6.5-2.9c-1-2.6,0.3-5.5,2.9-6.5
                                        C191.1,76.8,194.1,78,195.1,80.6z"/>
                                    <path className="st13" d="M193.9,80c0.8,2-0.2,4.3-2.2,5c-2,0.8-4.3-0.2-5-2.2c-0.8-2,0.2-4.3,2.2-5C190.8,77,193.1,78,193.9,80z"
                                        />
                                    <path className="st1" d="M193.6,78.5c0.8,2-0.2,4.3-2.2,5c-2,0.8-4.3-0.2-5-2.2c-0.8-2,0.2-4.3,2.2-5
                                        C190.6,75.5,192.9,76.5,193.6,78.5z"/>
                                </g>
                            </g>
                            <path className="st9" d="M177.6,47.4l4.1,0.1c6.3,0.2,11.6-4.8,11.8-11.1l0.7-20.8c0.2-6.3-4.8-11.6-11.1-11.8l-4.1-0.1
                                c-6.3-0.2-11.6,4.8-11.8,11.1l-0.7,20.8C166.4,41.8,171.3,47.2,177.6,47.4z"/>
                            <path className="st1" d="M178,19.2c4.3,2,9.6,3.1,14.3,1.3c5.1-2,8.8-9.2,4.7-13c-2.2-2-7.8-3.9-12.4-5.7c-13.2-5.3-27.5,1.4-28,16.4
                                c0,0-1,16.7,7.6,22.2c1.5,1,3.6,0.1,4-1.6l0,0c0,0-4.3-6.1,3-10.6c1.7-1,2.1-3.4,1.9-5.9C173.1,19.9,175.7,18.1,178,19.2z"/>
                            <path className="st1" d="M165.5,28.5l9,4.4c0.4,0.2,0.9,0,1.1-0.4l0-0.1c0.2-0.4,0-0.9-0.4-1.1l-9-4.4c-0.4-0.2-0.9,0-1.1,0.4l0,0.1
                                C165,27.8,165.1,28.3,165.5,28.5z"/>
                            <path className="st9" d="M164.3,36c1.8,1.5,3.9,1,4.7-1.2c0.8-2.2-0.1-5.2-2-6.7c-1.8-1.5-3.9-1-4.7,1.2
                                C161.6,31.5,162.4,34.5,164.3,36z"/>
                            <path className="st0" d="M189.8,35.7l-7.1,3c-0.3,0.1-0.5,0.4-0.4,0.7c0.2,1.1,1,3.5,4.8,2.1c2.7-1,3.5-3.6,3.7-5.1
                                C190.9,35.9,190.3,35.5,189.8,35.7z"/>
                            <path className="st14" d="M179.4,31.8c0,0.9,0.4,1.6,0.8,1.6c0.4,0,0.8-0.7,0.8-1.6c0-0.9-0.4-1.6-0.8-1.6
                                C179.7,30.2,179.4,30.9,179.4,31.8z"/>
                            <ellipse className="st14" cx="190.6" cy="27.6" rx="0.8" ry="1.6"/>
                            <g>
                                <path className="st1" d="M181.3,25.3c-2,0-4.5,1.1-5.9,2.6c-0.8,0.8,0.4,2,1.2,1.2c1.1-1.1,3.1-2.1,4.6-2.1
                                    C182.4,27,182.4,25.2,181.3,25.3L181.3,25.3z"/>
                            </g>
                            <g>
                                <path className="st1" d="M193,21c-2,0-4.5,1-5.9,2.4c-0.8,0.8,0.4,2,1.2,1.3c1.1-1.1,3.2-1.9,4.7-1.9C194,22.7,194.1,21,193,21
                                    L193,21z"/>
                            </g>
                            <g>
                                <path className="st15" d="M187.7,26.8l-0.1,1.5c0,0-0.3,4.4,4.1,2.2c4.4-2.2,4.4-7.7,4.4-7.7S192.8,22.3,187.7,26.8z"/>
                                <path className="st1" d="M197,21.6c0,0-4-0.4-10.5,4.9l-0.2,1.8l0,0c0,0-0.3,5.2,5.4,2.3C197.5,27.8,197,21.6,197,21.6z M191.7,29.9
                                    c-4.3,2.1-4-1.7-4-1.7l0.1-1.3c5-4.1,8.2-3.9,8.2-3.9S196,27.8,191.7,29.9z"/>
                                <polygon className="st1" points="184.2,29.2 187.6,27.8 187.8,25.9 184.4,27.3 				"/>
                                <path className="st1" d="M196.7,23.1l0.6-0.3c0.3-0.1,0.6-0.6,0.7-1l0,0c0-0.4-0.2-0.6-0.5-0.5l-0.6,0.3c-0.3,0.1-0.6,0.6-0.7,1l0,0
                                    C196.1,23,196.3,23.2,196.7,23.1z"/>
                                <path className="st16" d="M174.6,32.5l0.6-0.3c0.3-0.1,0.6-0.6,0.7-1l0,0c0-0.4-0.2-0.6-0.5-0.5l-0.6,0.3c-0.3,0.1-0.6,0.6-0.7,1
                                    l0,0C174,32.5,174.2,32.7,174.6,32.5z"/>
                                <g>
                                    <path className="st15" d="M184.1,28.5l-0.3,1.5c0,0-0.8,4.5-5,6c-4.2,1.5-2.8-4.4-2.8-4.4S179.7,28.1,184.1,28.5z"/>
                                    <path className="st1" d="M178.6,36.3c5.7-2.1,6.6-7.5,6.6-7.5l0,0l0.2-1.8c-5.8,0-10.4,4-10.4,4S172.9,38.3,178.6,36.3z
                                        M175.9,31.5c0,0,3.6-3.2,8.1-3.1l-0.2,1.3c0,0-0.7,4-4.9,5.6C174.6,36.9,175.9,31.5,175.9,31.5z"/>
                                    <path className="st11" d="M183,30.7L183,30.7c0.1-0.2,0.2-0.4,0.1-0.5l-1-1.1c-0.1-0.1-0.3,0-0.4,0.1l0,0c-0.1,0.2-0.2,0.4-0.1,0.5
                                        l1,1.1C182.6,31,182.8,30.9,183,30.7z"/>
                                </g>
                                <path className="st11" d="M195.2,25.3L195.2,25.3c0.1-0.2,0.2-0.4,0.1-0.5l-1-1.1c-0.1-0.1-0.3,0-0.4,0.1l0,0
                                    c-0.1,0.2-0.2,0.4-0.1,0.5l1,1.1C194.9,25.5,195.1,25.4,195.2,25.3z"/>
                            </g>
                            <path className="st9" d="M223.2,132.3l-1.3-0.7c-3.3-1.7-4.6-5.7-3-9l4.4-8.8c1.7-3.3,5.7-4.6,9-3l1.3,0.7c3.3,1.7,4.6,5.7,3,9
                                l-4.4,8.8C230.5,132.7,226.5,134,223.2,132.3z"/>
                        </g>
                        <path className="st3" d="M603.3,421.5L337.6,268.1c-3.1-1.8-2.7-4.9,0.9-7l127.3-73.5c3.6-2.1,9-2.3,12-0.5l265.7,153.4
                            c3.1,1.8,2.7,4.9-0.9,7L615.4,421C611.8,423.1,606.4,423.3,603.3,421.5z"/>
                        <g>
                            
                                <ellipse transform="matrix(5.423625e-03 -1 1 5.423625e-03 99.5234 531.1174)" className="st3" cx="316.8" cy="215.5" rx="23.5" ry="40.8"/>
                            
                                <ellipse transform="matrix(5.421847e-03 -1 1 5.421847e-03 86.9784 518.5041)" className="st17" cx="304.2" cy="215.5" rx="17.2" ry="29.8"/>
                            <polygon className="st17" points="260.5,173.8 274.5,216.7 333.7,217.7 347.2,176.3 			"/>
                            <path className="st18" d="M330.4,177l-13.5,41.9h0c-0.8,1.1-3.6,6.6-8.4,9.4c-3.5,2-7.7,3.2-12.1,4c10,1.7,21.1,0.6,29-3.9
                                c4.9-2.8,7.7-8.4,8.4-9.5h0l13.5-41.9L330.4,177z"/>
                            
                                <ellipse transform="matrix(5.422288e-03 -1 1 5.422288e-03 131.4212 474.3014)" className="st0" cx="304.2" cy="171.1" rx="25.4" ry="44"/>
                            
                                <ellipse transform="matrix(5.424919e-03 -1 1 5.424919e-03 131.9379 473.1817)" className="st1" cx="303.8" cy="170.3" rx="21.8" ry="37.7"/>
                            <g>
                                <path className="st5" d="M275.1,161.6L275.1,161.6c-2.2-2.9-4.2-6.3-3.7-10.2c0.2-1.7,0.9-3.3,2.2-4.5c3.4-3,8.1-1.1,11,1.8
                                    c2.5,2.5,4.2,5.6,6.9,7.9c-3.4-10.8-6.8-21.7-10.3-32.5c-1.3-4.2-2.6-8.6-0.4-12.8c1.2-2.3,3.7-3.7,6.3-3.3
                                    c6.5,1.1,5.6,12.1,11.4,15.3c-4.2-8.1-7.1-16.8-8.6-25.8c-1-6.5-1.2-13.6,3.5-18.9c2.6-2.9,6.6-4.4,10.3-3.2
                                    c6.7,2,8,10.4,8.2,17.2c0.3,8.5,0.6,16.9,0.9,25.4c1.5-6.2,3.7-13.2,8-17.5c2.3-2.3,6.1-0.8,6.4,2.4c0.8,9.8-1.2,19.9-5.6,28.6
                                    c0.8-1.4,1.9-2.7,3.2-3.7c3.4-2.7,8.4-0.2,8.3,4.1c-0.2,9.7-4.2,19.3-11,26.1l3.4-3.7c1.5-1.7,4.4-0.6,4.4,1.7
                                    c-0.1,6.5-2.9,13-7.8,17.3C306.8,186.3,285.2,174.8,275.1,161.6z"/>
                            </g>
                        </g>
                        <g>
                            <path className="st6" d="M231.9,598.6L88.5,515.8c-5.2-3-4.4-8.3,1.6-11.9l197.6-117.1c6-3.6,15.1-4.1,20.3-1.1l143.4,82.8
                                c5.2,3,4.4,8.3-1.6,11.9L252.1,597.5C246.1,601.1,237,601.6,231.9,598.6z"/>
                            <path className="st4" d="M444.4,466c-0.5-0.5-1.1-1-1.9-1.5l-143.4-82.8c-5.2-3-14.2-2.5-20.3,1.1L81.2,499.9
                                c-0.7,0.4-1.3,0.8-1.8,1.3l-3.2,1.5l0.1,4.7c0.3,1.6,1.4,3.2,3.4,4.3L223,594.6c5.2,3,14.2,2.5,20.3-1.1l197.6-117.1
                                c3.1-1.9,4.8-4.2,5-6.4l0-3.8L444.4,466z"/>
                            <path className="st19" d="M223,590.8L79.6,508c-5.2-3-4.4-8.3,1.6-11.9L278.8,379c6-3.6,15.1-4.1,20.3-1.1l143.4,82.8
                                c5.2,3,4.4,8.3-1.6,11.9L243.3,589.7C237.3,593.3,228.2,593.8,223,590.8z"/>
                            <polygon className="st0" points="231.9,578.1 94,498.5 292.1,384.2 430,463.8 			"/>
                            <path className="st6" d="M395.9,434l-4.7-2.7v0c5.6-3.2,5.6-8.4,0-11.6l-25.5-14.7c-5.6-3.2-14.6-3.2-20.1,0l-3.2-1.8
                                c-5.8-3.3-15.2-3.3-20.9,0v0c-5.8,3.3-5.8,8.8,0,12.1l53.5,30.9c5.8,3.3,15.2,3.3,20.9,0v0C401.7,442.7,401.7,437.3,395.9,434z"
                                />
                            <path className="heart" d="M309.6,439.3c8.1-4.7,8.5-12.1,0.9-16.5c-4.3-2.5-10.2-3.5-16.1-3.1c0.7-3.4-1-6.8-5.3-9.3
                                c-7.7-4.4-21-3.9-29.2,0.8c-1.6,0.9-3.1,2.1-4.4,3.4l0,0c0,0,0.5-0.3,0.5-0.2c-1.2,1.3-1.7,2.6-2,4c-2.7,8.9,2.4,22.6,2.4,22.6
                                c30.4,5.2,42.7,1.8,42.7,1.8l0.8-0.2C302.5,442.1,307.5,440.5,309.6,439.3z"/>
                            <path className="st20" d="M386.6,477.8l-63.1-37.5c-2.2-1.3-2.3-4.5-0.1-6h0c1.1-0.7,2.5-0.7,3.7-0.1l63.1,37.5
                                c2.2,1.3,2.3,4.5,0.1,6l0,0C389.2,478.4,387.8,478.4,386.6,477.8z"/>
                            <path className="st17" d="M334.7,500l-63.1-37.5c-0.1-0.1-0.2-0.1-0.3-0.2l-50.8-30.2c-1.1-0.7-2.6-0.7-3.7,0.1
                                c-2.2,1.4-2.1,4.6,0.1,6l63.1,37.5c0.1,0.1,0.2,0.1,0.3,0.2l50.8,30.2c1.1,0.7,2.6,0.7,3.7-0.1C337,504.6,336.9,501.4,334.7,500z
                                "/>
                            <path className="st17" d="M314,514.7l-63.1-37.5c-0.1-0.1-0.2-0.1-0.3-0.2l-50.8-30.2c-1.1-0.7-2.6-0.7-3.7,0.1
                                c-2.2,1.4-2.1,4.6,0.1,6l63.1,37.5c0.1,0.1,0.2,0.1,0.3,0.2l50.8,30.2c1.1,0.7,2.6,0.7,3.7-0.1C316.3,519.2,316.3,516,314,514.7z
                                "/>
                            <path className="st17" d="M293.4,529.3l-63.1-37.5c-0.1-0.1-0.2-0.1-0.3-0.2l-50.8-30.2c-1.1-0.7-2.6-0.7-3.7,0.1
                                c-2.2,1.4-2.1,4.6,0.1,6l63.1,37.5c0.1,0.1,0.2,0.1,0.3,0.2l50.8,30.2c1.1,0.7,2.6,0.7,3.7-0.1
                                C295.7,533.8,295.6,530.6,293.4,529.3z"/>
                            <path className="st20" d="M374.4,488.7l-63.1-37.5c-2.2-1.3-2.3-4.5-0.1-6l0,0c1.1-0.7,2.5-0.7,3.7-0.1l63.1,37.5
                                c2.2,1.3,2.3,4.5,0.1,6h0C377,489.3,375.5,489.3,374.4,488.7z"/>
                            <path className="st20" d="M238.1,558l-31.2-18.5c-1.5-0.9-3.5-0.5-4.6,0.9l0,0c-1.3,1.7-0.8,4.1,1,5.2l31.3,18.6
                                c1.4,0.9,3.4,0.6,4.5-0.8C240.6,561.6,240,559.2,238.1,558z"/>
                            <path className="st20" d="M254.2,547.9l-47.7-28.3c-1.5-0.9-3.5-0.5-4.5,0.8l0,0c-1.3,1.7-0.9,4.1,1,5.2l47.8,28.4
                                c1.4,0.9,3.4,0.6,4.5-0.8C256.7,551.5,256.1,549,254.2,547.9z"/>
                            <path className="st6" d="M320.1,488.8l-0.4-11.1c-0.1-2.5-2.2-4.5-4.7-4.4l-1.6,0.1c-1.6,0.1-3,0.9-3.8,2.2l-99.3,4
                                c-2.6,0.1-4.9,1.4-6.4,3.4l-0.2-0.1c-5.9-2.4-12.6-1.7-17.9,1.8l-5.6,3.7l-2.5,1.7c-0.5,0.3-0.4,1,0.1,1.3l2.7,1.3l6.7,3
                                c5.4,2.4,11.5,2.2,16.7-0.5l1.5-0.7c1.5,1.2,3.5,2,5.6,1.9l96.3-3.8c-0.4,2-1.3,3.9-2.8,5.3c-1.8,1.8-4.3,2.7-6.9,2.8l-42.8,1.4
                                c-1.1,0-1.9,0.9-1.9,2c0,1.1,0.9,1.9,2,1.9l43.1-1.4c3.8-0.1,7.3-1.8,9.9-4.6c1.8-2,2.8-4.5,3.3-7.1c0.8,0.6,1.8,1,2.9,1l1.6-0.1
                                C318.2,493.5,320.2,491.4,320.1,488.8z"/>
                            <g>
                                <path className="st4" d="M209.2,479.1l-2.8-1.1c-5.9-2.4-12.6-1.7-17.9,1.8l-5.6,3.7l-2.5,1.7c-0.5,0.3-0.4,1,0.1,1.3l2.7,1.3l6.7,3
                                    c5.4,2.4,11.5,2.2,16.7-0.5l3.2-1.6L209.2,479.1z"/>
                                <path className="st4" d="M318.8,487l-105.2,4.2c-4.6,0.2-8.5-3.4-8.6-8l0,0c-0.1-4.5,3.4-8.4,7.9-8.5l104.5-4.2L318.8,487z"/>
                                <path className="st19" d="M312,484.2l-0.4-11.1c-0.1-2.5,1.9-4.6,4.4-4.7l1.6-0.1c2.5-0.1,4.6,1.9,4.7,4.4l0.4,11.1
                                    c0.1,2.5-1.9,4.6-4.4,4.7l-1.6,0.1C314.2,488.7,312,486.7,312,484.2z"/>
                                <g>
                                    <g>
                                        <path className="st4" d="M255.5,498.9L255.5,498.9c0-1.1,0.8-2,1.9-2l42.8-1.4c2.6-0.1,5.1-1,6.9-2.8c2-2,3.1-4.6,3-7.4l-0.4-13.8
                                            l3.9-0.1l0.4,13.4c0.1,3.6-1,7.3-3.5,10c-2.5,2.9-6.1,4.5-9.9,4.6l-43.1,1.4C256.5,500.8,255.6,500,255.5,498.9z"/>
                                    </g>
                                </g>
                            </g>
                            <path className="st17" d="M395.9,429.4l-4.7-2.7l0,0c5.6-3.2,5.6-8.4,0-11.6l-25.5-14.7c-5.6-3.2-14.6-3.2-20.1,0l-3.2-1.8
                                c-5.8-3.3-15.2-3.3-20.9,0l0,0c-5.8,3.3-5.8,8.8,0,12.1l53.5,30.9c5.8,3.3,15.2,3.3,20.9,0l0,0
                                C401.7,438.2,401.7,432.8,395.9,429.4z"/>
                            <path className="st0" d="M381.3,428.4l-39.1-23.2c-1.4-0.8-1.4-2.8-0.1-3.7l0,0c0.7-0.4,1.6-0.5,2.3,0l39.1,23.2
                                c1.4,0.8,1.4,2.8,0.1,3.7h0C382.9,428.8,382,428.8,381.3,428.4z"/>
                        </g>
                        <g>
                            <path className="st3" d="M469.1,539.5c-9.5,6.2-24.9,6.8-35,1.4c-0.3,0.2-0.6,0.4-0.9,0.5c1.3,1.9,3.2,3.6,5.7,5.1
                                c10.1,6,26.3,5.7,36.1-0.8c9.5-6.3,9.6-15.9,0.4-22c0,0,0,0.1,0,0.1C477.8,529.2,475.7,535.2,469.1,539.5z"/>
                            <path className="st3" d="M540.1,576.3c-10.3,8-20.9,15.5-33.5,19.4c-5.4,1.7-11.2,2.6-16.9,2.5c-4.8-0.1-9.6-0.7-14.4-0.5
                                c-8.7,0.3-16.4,5.2-22.5,11c-6.9,6.6-13.5,15-23.4,17.1c-9.8,2.1-19.6-1.9-27.9-7.2c0.3,0.2,0.5,0.4,0.8,0.6
                                c8.7,6.6,19.8,12.6,31,11.2c10.2-1.3,17.1-9.1,24-15.9c6.2-6.2,14.1-12,23.1-12.5c4.6-0.3,9.3,0.2,13.9,0.4
                                c5.9,0.3,11.9-0.5,17.5-2.2c12.7-3.7,23.5-11.3,33.9-19.3c2.7-2.1,5.3-4.1,7.9-6.1l-6.7-3.7C544.6,572.7,542.4,574.5,540.1,576.3
                                z"/>
                            <path className="st3" d="M433,540.3c-1.9-1.1-3.5-2.4-4.7-3.8c-5.1,3-10.3,6.1-15.3,9.2c-10,6.2-19.7,13.8-24.6,24.9
                                c-3.9,9.1-3.4,18.8,2.1,27.1c3.7,5.5,9.1,10.3,15,13.9c-3.5-2.8-6.5-6-8.9-9.5c-5.8-8.6-6.1-18.8-1.8-28.1
                                c5-10.8,14.5-18.1,24.3-24.2c4.6-2.9,9.3-5.7,14-8.4c0.3-0.2,0.6-0.4,0.9-0.5C433.8,540.7,433.4,540.5,433,540.3z"/>
                            <path className="st3" d="M653.1,492l-14-7.2c-0.7-0.3-1.2-0.8-1.7-1.3c-7.4-0.9-15.1-0.4-22,1.5c-3.2,1.4-6.5,3.3-10.1,5.3l-13.1,7.9
                                c0,0,0,0,0,0L549.9,524c-12.2,7-17.5,11.4-12.1,17.1c2.1-2.8,6.5-5.7,12.9-9.4l42.2-25.7c0,0,0,0,0,0l13.1-7.9
                                c11.3-6.5,19.6-10.6,31.4-7.9c0.5,1,1.4,1.8,2.5,2.4l14,7.2c2.9,1.5,6.4,0.3,7.9-2.6c1.2-2.4,0.6-5.3-1.3-7
                                C658.9,492.5,655.7,493.4,653.1,492z"/>
                            <path className="st3" d="M678.1,552.9c0.5-0.2,0.8-0.3,0.8-0.3l19.1-11.4c8.9-5.2,11.8-12.6,8.8-19.2c-0.9,4.2-4.1,8.2-9.7,11.5
                                l-19.1,11.4c0,0-0.3,0.1-0.8,0.3c-0.2,0.2-0.3,0.3-0.6,0.5l-52.7,31.2c-13.1,7.7-29.6,10-49.1-3.5c-4.1,1.2-9.2,0.9-12.6-1.1
                                l-9.4-5.2c-1.9,1.3-3.8,2.7-5.7,4.1l7.2,4l8.7,4.8c3.5,1.9,8.6,2.2,12.6,1.1c19.5,13.5,36,11.2,49.1,3.5l52.7-31.2
                                C677.8,553.2,678,553,678.1,552.9z"/>
                            <path className="st3" d="M537.6,558.6c-3.4-1.9-4.2-4.7-2.5-7.1c-5.5-3-8.8-6-10.5-9c-1.7,5.3,1,11,11.4,16.7
                                c-1.7,2.4-0.9,5.2,2.5,7.1l0.2,0.1c1.8-1.4,3.7-2.8,5.6-4.2L537.6,558.6z"/>
                            <path className="st3" d="M691.1,511.2c-1.5,0.5-3.1,0.5-4.6-0.3l-14-7.2c-0.5-0.2-0.9-0.6-1.3-0.9c-0.2,0.2-0.3,0.5-0.4,0.7
                                c-1.5,2.9-0.3,6.4,2.6,7.9l14,7.2c1.5,0.8,3.1,0.8,4.6,0.3c1.5,1.2,2.7,2.3,3.5,3.4C697.4,518.9,696.4,515.4,691.1,511.2z"/>
                            <path className="st0" d="M677.3,545.2c0.5-0.2,0.8-0.3,0.8-0.3l19.1-11.4c5.5-3.2,8.7-7.3,9.7-11.5c1.4-6.1-2.1-12.4-10.2-17.1
                                l-2-1.1c0.5,1.5,0.4,3.1-0.3,4.6c-0.7,1.4-1.9,2.4-3.3,2.9c5.4,4.2,6.3,7.7,4.3,11.1c-2,3.4-6.8,6.8-13.1,10.7l-10.3,6.2l0,0
                                C675.1,537.3,679.3,542.8,677.3,545.2z"/>
                            <path className="st21" d="M581.7,569.8l-3.8,2.3c-0.9,0.5-1.9,0.9-2.9,1.3c19.5,13.5,36,11.2,49.1,3.5l52.7-31.2
                                c0.2-0.1,0.4-0.3,0.6-0.5c2-2.3-2.2-7.8-5.2-6l0,0L625.8,566c-15.9,10.1-24.1,12.4-41.8,1.7C583.5,568.5,582.7,569.2,581.7,569.8
                                z"/>
                            <path className="st21" d="M541.9,554.7c-2.5-1-4.8-2.1-6.8-3.2c-1.7,2.4-0.9,5.2,2.5,7.1l6.7,3.7c3.9-2.8,11.2,3,8.6,4.8l9.4,5.2
                                c3.5,1.9,8.6,2.2,12.6,1.1c1.1-0.3,2.1-0.7,2.9-1.3l3.8-2.3c1-0.6,1.8-1.3,2.3-2.1c1.6-2.3,0.7-5.1-2.6-6.9l-24.7-13.7
                                c-3.3-1.8-8.1-2.2-12.1-1.2c2.3,1.3,5.1,2.7,8.3,4.3L541.9,554.7z"/>
                            <path className="st0" d="M592.1,498.3l13.1-7.9c3.6-2.1,6.9-3.9,10.1-5.3c6.9-3.1,13.3-4.4,21.3-2.6c-0.9-1.6-1-3.7-0.1-5.5
                                c0.2-0.4,0.4-0.7,0.7-1c-11.7-1.5-24.5,0.4-33.3,5.9l-17.1,10.4C590.3,489.9,594,497,592.1,498.3z"/>
                            <path className="st21" d="M535.1,551.6c2,1.1,4.2,2.1,6.8,3.2l11-4.5c-3.3-1.5-6-2.9-8.3-4.3c-3.1-1.8-5.3-3.4-6.8-4.9
                                c-5.4-5.6-0.2-10.1,12.1-17.1l42.2-25.7c0,0,0,0,0,0c1.8-1.2-1.8-8.4-5.4-6.1l0,0l-51.9,31.1c-8.5,5.2-14.3,12.1-10.2,19.3
                                C526.3,545.6,529.6,548.6,535.1,551.6z"/>
                            <path className="st21" d="M636.6,482.5c0.2,0.4,0.5,0.8,0.8,1.1c0.5,0.5,1,1,1.7,1.3l14,7.2c2.6,1.3,5.8,0.5,7.5-1.9
                                c0.2-0.2,0.3-0.5,0.4-0.7l0,0c1.5-2.9,0.3-6.4-2.6-7.9l-14-7.2c-2.5-1.3-5.5-0.6-7.2,1.5c-0.3,0.3-0.5,0.7-0.7,1
                                C635.6,478.8,635.7,480.8,636.6,482.5z"/>
                            <path className="st21" d="M686.5,510.9c1.5,0.8,3.1,0.8,4.6,0.3c1.4-0.5,2.6-1.5,3.3-2.9c0.7-1.5,0.8-3.1,0.3-4.6
                                c-0.5-1.4-1.5-2.6-2.9-3.3l-14-7.2c-2.9-1.5-6.4-0.3-7.9,2.6c-1.2,2.4-0.6,5.3,1.3,7c0.4,0.3,0.8,0.7,1.3,0.9L686.5,510.9z"/>
                            <path className="st8" d="M433,540.3c0.4,0.2,0.7,0.4,1.1,0.6c10.1,5.4,25.5,4.8,35-1.4c6.6-4.3,8.7-10.3,6.2-15.6
                                c-0.7,1.6-1.7,3.2-3.2,4.7l0,1.6l-1.9,0c-0.4,0.3-0.8,0.6-1.3,0.9c-9.3,6.1-24.2,6.7-34.4,1.7c-2.1,1.2-4.1,2.4-6.2,3.6
                                C429.6,537.9,431.1,539.2,433,540.3z"/>
                            <path className="st22" d="M472.1,528.6c-0.6,0.6-1.2,1.1-1.9,1.6l1.9,0L472.1,528.6z"/>
                            <path className="st0" d="M438.9,520.9c-0.7-2.3,0.3-4.9,3.2-6.8c4.7-3.1,12.3-3.2,17.1-0.4c4.8,2.8,4.9,7.6,0.2,10.7
                                c-3.6,2.4-9.1,3-13.6,1.8c-3.7,2.2-7.5,4.4-11.3,6.6c10.1,5,25.1,4.4,34.4-1.7c0.5-0.3,0.9-0.6,1.3-0.9c0.7-0.5,1.3-1.1,1.9-1.6
                                c1.5-1.5,2.6-3.1,3.2-4.7c0,0,0-0.1,0-0.1c2.1-5.4-0.2-11.2-7-15.2c-10.1-6-26.3-5.7-36.1,0.8c-6.6,4.3-8.7,10.3-6.2,15.6
                                c0.4,0.9,0.9,1.7,1.5,2.5C431.4,525.3,435.1,523.1,438.9,520.9z"/>
                            <path className="st21" d="M459.4,524.5c4.7-3.1,4.6-7.8-0.2-10.7c-4.8-2.8-12.4-2.7-17.1,0.4c-2.9,1.9-3.9,4.4-3.2,6.8
                                c1.7-1,3.4-2,5.1-3c4.5-2.7,8.6,4.4,4.1,7.1c-0.7,0.4-1.5,0.9-2.2,1.3C450.3,527.5,455.8,526.8,459.4,524.5z"/>
                            <path className="st21" d="M429.4,625.8c9.9-2.1,16.5-10.5,23.4-17.1c6.1-5.9,13.8-10.7,22.5-11c4.8-0.2,9.6,0.4,14.4,0.5
                                c5.7,0.1,11.5-0.8,16.9-2.5c12.6-3.9,23.2-11.4,33.5-19.4c2.3-1.8,4.5-3.6,6.8-5.3c1.7-1.3,3.5-2.6,5.2-3.8
                                c2.4-1.6,4.9-3.2,7.5-4.5c4.7-2.4,0.5-9.5-4.1-7.1c-4,2.1-7.8,4.5-11.4,7.1c-1.7,1.3-3.5,2.5-5.2,3.9c-7.8,6-15.4,12.4-24.2,16.9
                                c-4.8,2.5-9.9,4.4-15.2,5.5c-5.2,1.1-10.3,0.9-15.6,0.6c-8-0.4-15.5-0.2-22.9,3.3c-8.8,4.3-15,11.3-22,17.9
                                c-3,2.9-6.4,5.6-10.4,6.7c-5,1.4-10.3,0.2-15-1.8c-2.7-1.2-5.4-2.6-8.1-4.2c-5.9-3.6-11.3-8.4-15-13.9c-5.5-8.2-6-18-2.1-27.1
                                c4.8-11.1,14.5-18.6,24.6-24.9c5.1-3.1,10.2-6.2,15.3-9.2c2.1-1.2,4.1-2.4,6.2-3.6c3.8-2.2,7.5-4.4,11.3-6.6
                                c0.7-0.4,1.5-0.9,2.2-1.3c4.5-2.7,0.4-9.7-4.1-7.1c-1.7,1-3.4,2-5.1,3c-3.7,2.2-7.5,4.4-11.2,6.6c-0.8,0.5-1.6,0.9-2.3,1.4
                                c-4.9,2.9-9.9,5.8-14.8,8.8c-9.8,6-19.3,12.8-25.7,22.5c-6,9.2-9,20.9-5.9,31.7c2.9,10,10.3,17.8,18.6,23.8
                                c1.3,0.9,2.6,1.8,4,2.7C409.8,623.8,419.6,627.9,429.4,625.8z"/>
                        </g>
                        <g>
                            <path className="st3" d="M271.6,356.7l0.1-6.6l0,0c0-1.3-0.8-2.6-2.3-3.5l-59-35.1c-3.5-2.1-9.8-1.9-13.9,0.4L88,372.6
                                c-2.2,1.2-3.3,2.8-3.4,4.3l0,0l0,0.3c0,0.1,0,0.1,0,0.2l0.1,5.5l0,0c-0.6,1.7,0.1,3.4,2.2,4.6l59,35.1c1.8,1.1,4.2,1.5,6.7,1.5
                                c0.6,0,1.3-0.1,1.9-0.2c1.3-0.2,2.5-0.5,3.6-0.9c0.6-0.2,1.1-0.5,1.6-0.8l108.5-60.7c2.2-1.2,3.4-2.8,3.4-4.4l0,0v0
                                C271.6,357,271.6,356.8,271.6,356.7z"/>
                            <g>
                                <path className="st6" d="M76.9,378.3l-0.1-6l7.6,5.1c0,0-2.2,5.4-2.5,5.2S76.9,378.3,76.9,378.3z"/>
                                <path className="st23" d="M138,418.1l-59-35.1c-3.5-2.1-3-5.7,1.1-8l108.5-60.7c4.1-2.3,10.4-2.5,13.9-0.4l59,35.1
                                    c3.5,2.1,3,5.7-1.1,8l-108.5,60.7C147.8,420,141.6,420.2,138,418.1z"/>
                                <path className="st11" d="M145,385.1l43.7-70.9c4.1-2.3,10.4-2.5,13.9-0.4l59,35.1c3.5,2.1,3,5.7-1.1,8l-108.5,60.7
                                    c-2.1,1.2-4.7,1.8-7.2,1.9L145,385.1z"/>
                                <polygon className="st11" points="192.1,362.1 203.2,347.4 264,345.7 263.9,352.6 197.2,372.9 				"/>
                                <path className="st4" d="M134.9,412.5c0,1-0.7,1.4-1.6,0.9c-0.9-0.5-1.6-1.8-1.6-2.8c0-1,0.7-1.4,1.6-0.9
                                    C134.2,410.2,134.9,411.4,134.9,412.5z"/>
                                <path className="st4" d="M138.1,411.3l-59-35.1c-3.5-2.1-3-5.7,1.1-8l108.5-60.7c4.1-2.3,10.4-2.5,13.9-0.4l59,35.1
                                    c3.5,2.1,3,5.7-1.1,8L152,410.9C147.9,413.2,141.7,413.4,138.1,411.3z"/>
                                <path className="st24" d="M234,331.7l-16.3-9.7c-0.7-0.4-0.6-1.1,0.2-1.5l0,0c0.8-0.4,2-0.5,2.7-0.1l16.3,9.7
                                    c0.7,0.4,0.6,1.1-0.2,1.5l0,0C235.9,332.1,234.7,332.1,234,331.7z"/>
                                <path className="st4" d="M113.8,401.5l-13.1-7.8c-0.6-0.3-1-1.2-1-2l0,0c0-0.7,0.5-1.1,1-0.8l13.1,7.8c0.6,0.3,1,1.2,1,2l0,0
                                    C114.9,401.5,114.4,401.8,113.8,401.5z"/>
                                <path className="st4" d="M245,363.2l6.5-3.7c0.4-0.2,0.7-0.8,0.7-1.3l0,0c0-0.5-0.3-0.8-0.7-0.5l-6.5,3.7c-0.4,0.2-0.7,0.8-0.7,1.3
                                    l0,0C244.3,363.2,244.6,363.4,245,363.2z"/>
                                <path className="st4" d="M235.7,368.9l6.5-3.7c0.4-0.2,0.7-0.8,0.7-1.3l0,0c0-0.5-0.3-0.8-0.7-0.5l-6.5,3.7
                                    c-0.4,0.2-0.7,0.8-0.7,1.3l0,0C235,368.9,235.3,369.1,235.7,368.9z"/>
                                <path className="st25" d="M192,311l-91.2,51c-2.1,1.2-2.2,4.2-0.1,5.5l54.5,32.5c1,0.6,2.2,0.6,3.2,0l91.2-51
                                    c2.1-1.2,2.2-4.2,0.1-5.5l-54.5-32.5C194.2,310.5,193,310.5,192,311z"/>
                            </g>
                            
                                <ellipse transform="matrix(2.334514e-02 -0.9997 0.9997 2.334514e-02 -180.8803 520.8732)" className="st26" cx="176.1" cy="353" rx="16.8" ry="29.1"/>
                            <g>
                                <path className="st11" d="M159.8,345.4l5.4-3c0.5-0.3,1.2-0.3,1.7,0l25.6,15.3c1.1,0.7,1.1,2.3,0,3l-5.4,3c-0.5,0.3-1.2,0.3-1.7,0
                                    l-25.6-15.3C158.6,347.7,158.6,346,159.8,345.4z"/>
                                <path className="st11" d="M187.2,342.7l5.4,3.2c1.1,0.7,1.1,2.3,0,3l-25.8,14.4c-0.5,0.3-1.2,0.3-1.7,0l-5.4-3.2
                                    c-1.1-0.7-1.1-2.3,0-3l25.8-14.4C186,342.4,186.7,342.4,187.2,342.7z"/>
                            </g>
                        </g>
                        <path className="st17" d="M703.4,203.3C703.4,203.3,703.4,203.3,703.4,203.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
                            c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
                            c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
                            c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3
                            c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0
                            c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.3-0.2-0.4
                            c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2
                            c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
                            c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0
                            c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                            c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-1.4-0.8-1.4-0.8l-7.9,4.3
                            c0,0,1.9,1.1,2,1.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1
                            c0.1,0.1,0.3,0.2,0.4,0.3h0c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.4c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.4,0.4c0,0,0,0,0.1,0.1
                            c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3
                            c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1
                            c0.1,0.2,0.2,0.3,0.2,0.5c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0.1c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0.1
                            c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1
                            c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
                            c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2c0,0,0,0.2-0.1,0.4l-27.2,168c-0.1,0.8-3.2,5.9-2.5,5.5l7.4-6.3
                            c1.6-1.3,2.6-3.2,2.9-5.2L703,206.5c0.1-0.8,0.3-2,0.3-2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0
                            c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3C703.4,203.5,703.4,203.5,703.4,203.3C703.4,203.4,703.4,203.4,703.4,203.3z"/>
                        <g>
                            <g>
                                <path className="st17" d="M690.5,198.4c3.5,2,5.9,6.7,5.3,10.5l-27.6,170.4c-0.6,3.8-4,5.1-7.5,3.1l-261-150.7
                                    c-3.5-2-5.9-6.7-5.3-10.5L422,50.8c0.6-3.8,4-5.1,7.5-3.1L690.5,198.4z"/>
                            </g>
                        </g>
                        <path className="st27" d="M699.6,173.3L440.8,23.9h0c0,0,0,0,0,0l-0.4-0.2c0,0,0,0,0,0c-0.3-0.2-0.6-0.4-0.7-0.4
                            c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1
                            c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
                            c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
                            c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0
                            c0,0,0,0-0.1,0c-0.2,0.1-0.4,0.2-0.6,0.3l-7.6,4.4c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
                            c0.1,0,0.2,0,0.4-0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.5,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0c0.2,0,0.4,0.1,0.6,0.1
                            c0,0,0,0,0,0c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0.1,0c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
                            l1.4,0.8l0,0l259.1,149.6c0.3,0.2,0.7,0.2,1,0l5.7-3.3C700.2,174.6,700.2,173.7,699.6,173.3z M438.3,24.4L438.3,24.4L438.3,24.4
                            L438.3,24.4z"/>
                        <path className="st17" d="M706.6,183.5C706.6,183.5,706.6,183.5,706.6,183.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
                            c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
                            c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
                            c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3
                            c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0
                            c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.3-0.2-0.4
                            c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2
                            c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
                            c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0
                            c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                            c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-6.3-3.6-6.3-3.6l-7.8,4.7
                            c0,0,6.7,3.5,6.7,3.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1
                            c0.1,0.1,0.3,0.2,0.4,0.3l0,0c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.4c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.4,0.4c0,0,0,0,0.1,0.1
                            c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3
                            c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0,0.1
                            c0.1,0.2,0.2,0.3,0.2,0.5c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0.1c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0.1
                            c0.1,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1
                            c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0.1c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
                            c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2c0,0,0,0.2-0.1,0.4l-27.2,168c-0.1,0.8-3.2,5.9-2.5,5.5l7.4-6.3
                            c1.6-1.3,2.6-3.2,2.9-5.2l26.7-164.8c0.1-0.8,0.3-2,0.3-2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0
                            c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3C706.6,183.7,706.6,183.6,706.6,183.5C706.6,183.5,706.6,183.5,706.6,183.5z"/>
                        <g>
                            <g>
                                <g>
                                    <path className="st4" d="M693.4,178.5c3.5,2,5.9,6.7,5.3,10.5l-27.6,170.4c-0.6,3.8-4,5.1-7.5,3.1l-261-150.7
                                        c-3.5-2-5.9-6.7-5.3-10.5l27.6-170.4c0.6-3.8,4-5.1,7.5-3.1L693.4,178.5z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path className="st7" d="M684.1,189.5c3.4,1.9,5.6,6,5.1,9.2l-24.9,143c-0.5,3.2-3.7,4.2-7.1,2.3L409.5,203.7
                                    c-3.4-1.9-5.6-6-5.1-9.2l24.9-143c0.6-3.2,3.7-4.2,7.1-2.3L684.1,189.5z"/>
                            </g>
                        </g>
                        <path className="st0" d="M565.5,114.5c0,1.6-1.2,2.3-2.6,1.5c-1.4-0.8-2.6-2.8-2.6-4.5c0-1.6,1.2-2.3,2.6-1.5
                            C564.3,110.8,565.5,112.8,565.5,114.5z"/>
                        <path className="st4" d="M663.9,392.3l-0.1-7.9l-10.2,3.9l-2.2-5L396.1,235.8c-3.1-1.8-8.2-1.6-11.8,0.5l-109.7,63.5V298l-20.1,8.2
                            v6.2h-0.6c0.3,3.5,0.9,2.7,1.9,3.3l265.7,154c3.1,1.8,8.5,1.8,12-0.2l127.3-73.4C662.9,394.9,663.9,393.6,663.9,392.3L663.9,392.3
                            z"/>
                        <path className="st0" d="M521.4,461.8L255.7,308.4c-3.1-1.8-2.7-4.9,0.9-7l127.3-73.5c3.6-2.1,9-2.3,12-0.5l265.7,153.4
                            c3.1,1.8,2.7,4.9-0.9,7l-127.3,73.5C529.9,463.4,524.5,463.6,521.4,461.8z"/>
                        <path className="st6" d="M422.4,387.7l-64.3-37.1c-0.7-0.4-0.7-1.2,0.2-1.7l30.8-17.8c0.9-0.5,2.2-0.6,2.9-0.1l64.3,37.1
                            c0.7,0.4,0.7,1.2-0.2,1.7l-30.8,17.8C424.4,388.1,423.1,388.1,422.4,387.7z"/>
                        <g>
                            <path className="st20" d="M399.8,255.9c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L399.8,255.9z"/>
                            <path className="st20" d="M418.5,283.7c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1L411,267c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L418.5,283.7z"/>
                            <path className="st20" d="M409.1,295.2c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L409.1,295.2z"/>
                            <path className="st20" d="M383.5,297.4c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L383.5,297.4z"/>
                            <path className="st20" d="M339.1,291c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l12.6-7.3c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L339.1,291z"/>
                            <path className="st20" d="M366.9,307c-0.4,0.2-1.3,0-2.1-0.4l-21.1-12.2c-0.8-0.4-1.1-1-0.7-1.2l12.6-7.3c0.4-0.2,1.3,0,2.1,0.4
                                l21.1,12.2c0.8,0.4,1.1,1,0.7,1.2L366.9,307z"/>
                            <path className="st20" d="M505.7,387.3c-0.3,0.2-1.1,0.1-1.6-0.3l-16.1-9.3c-0.6-0.3-0.8-0.8-0.5-1l4.6-2.7c0.3-0.2,1.1-0.1,1.6,0.3
                                l16.1,9.3c0.6,0.3,0.8,0.8,0.5,0.9L505.7,387.3z"/>
                            <path className="st20" d="M527.3,400c-0.3,0.2-1.1,0.1-1.6-0.3l-16.1-9.3c-0.6-0.3-0.8-0.8-0.5-1l4.6-2.7c0.3-0.2,1.1-0.1,1.6,0.3
                                l16.1,9.3c0.6,0.3,0.8,0.8,0.5,0.9L527.3,400z"/>
                            <path className="st20" d="M550.1,412.9c-0.3,0.2-1,0-1.6-0.3l-16.1-9.3c-0.6-0.3-0.8-0.8-0.5-0.9l4.5-2.6c0.3-0.2,1,0,1.6,0.3
                                l16.1,9.3c0.6,0.3,0.8,0.8,0.5,0.9L550.1,412.9z"/>
                            <path className="st20" d="M536.1,395.1c-0.3,0.2-1,0-1.6-0.3l-16.1-9.3c-0.6-0.3-0.8-0.8-0.5-0.9l4.5-2.6c0.3-0.2,1,0,1.6,0.3
                                l16.1,9.3c0.6,0.3,0.8,0.8,0.5,0.9L536.1,395.1z"/>
                            <path className="st20" d="M477.9,371c-0.4,0.2-1.2,0.1-1.8-0.2l-105-60.6c-0.6-0.3-0.7-0.8-0.4-1l12.6-7.3c0.4-0.2,1.2-0.1,1.8,0.2
                                l105,60.6c0.6,0.3,0.7,0.8,0.4,1L477.9,371z"/>
                            <path className="st20" d="M467.1,361.8c0,0-0.4-0.2-0.9-0.5l-83.6-48.2c-0.5-0.3-0.8-0.5-0.8-0.5l0.8-0.5c0,0,0.4,0.2,0.9,0.5
                                l83.6,48.2c0.5,0.3,0.8,0.5,0.8,0.5L467.1,361.8z"/>
                            <path className="st20" d="M364,303c0,0-0.1,0-0.2,0l-12.3-7.1c-0.1,0-0.1-0.1-0.1-0.1l0.8-0.5c0,0,0.1,0,0.2,0l12.3,7.1
                                c0.1,0,0.1,0.1,0.1,0.1L364,303z"/>
                            <path className="st20" d="M332.4,283.4C332.4,283.4,332.3,283.4,332.4,283.4l-5.3-3c0,0,0,0,0-0.1l0.8-0.5c0,0,0.1,0,0.1,0l5.2,3
                                c0,0,0,0,0,0.1L332.4,283.4z"/>
                            <path className="st20" d="M405.6,310.2c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L405.6,310.2z"/>
                            <path className="st20" d="M427.8,323c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L427.8,323z"/>
                            <path className="st20" d="M450,335.8c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L450,335.8z"/>
                            <path className="st20" d="M472.2,348.6c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L472.2,348.6z"/>
                            <path className="st20" d="M494.4,361.5c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L494.4,361.5z"/>
                            <path className="st20" d="M516.6,374.3c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L516.6,374.3z"/>
                            <path className="st20" d="M566.7,403.1c-0.4,0.2-1.2,0.1-1.8-0.2l-44-25.4c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l44,25.4c0.6,0.3,0.7,0.8,0.4,1L566.7,403.1z"/>
                            <path className="st20" d="M431.2,307.9c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L431.2,307.9z"/>
                            <path className="st20" d="M453.4,320.8c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L453.4,320.8z"/>
                            <path className="st20" d="M475.6,333.6c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L475.6,333.6z"/>
                            <path className="st20" d="M497.8,346.4c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L497.8,346.4z"/>
                            <path className="st20" d="M520,359.2c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L520,359.2z"/>
                            <path className="st20" d="M542.2,372c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.8-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L542.2,372z"/>
                            <path className="st20" d="M564.4,384.9c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L564.4,384.9z"/>
                            <path className="st20" d="M440.6,296.4c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L440.6,296.4z"/>
                            <path className="st20" d="M462.8,309.2c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L462.8,309.2z"/>
                            <path className="st20" d="M485.1,322c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L485.1,322z"/>
                            <path className="st20" d="M507.3,334.9c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L507.3,334.9z"/>
                            <path className="st20" d="M529.5,347.7c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L529.5,347.7z"/>
                            <path className="st20" d="M551.7,360.5c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L551.7,360.5z"/>
                            <path className="st20" d="M573.9,373.3c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L573.9,373.3z"/>
                            <path className="st20" d="M396.3,270.8c-0.4,0.2-1.2,0.1-1.8-0.2l-27.4-15.8c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2
                                c0.4-0.2,1.2-0.1,1.7,0.2l27.4,15.8c0.6,0.3,0.7,0.8,0.4,1L396.3,270.8z"/>
                            <path className="st20" d="M610.8,377.6c-0.4,0.2-1.2,0.1-1.8-0.2l-27.4-15.8c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2
                                c0.4-0.2,1.2-0.1,1.8,0.2l27.4,15.8c0.6,0.3,0.7,0.8,0.4,1L610.8,377.6z"/>
                            <path className="st20" d="M386.8,282.4c-0.4,0.2-1.2,0.1-1.8-0.2l-32.6-18.8c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2
                                c0.4-0.2,1.2-0.1,1.7,0.2l32.6,18.8c0.6,0.3,0.7,0.8,0.4,1L386.8,282.4z"/>
                            <path className="st20" d="M361.3,284.6c-0.4,0.2-1.2,0.1-1.8-0.2l-21.7-12.5c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2
                                c0.4-0.2,1.2-0.1,1.8,0.2l21.7,12.5c0.6,0.3,0.7,0.8,0.4,1L361.3,284.6z"/>
                            <path className="st20" d="M422,268.7c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L422,268.7z"/>
                            <path className="st20" d="M444.1,281.4c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L444.1,281.4z"/>
                            <path className="st20" d="M466.3,294.2c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L466.3,294.2z"/>
                            <path className="st20" d="M488.6,307.1c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L488.6,307.1z"/>
                            <path className="st20" d="M510.8,319.9c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L510.8,319.9z"/>
                            <path className="st20" d="M533,332.7c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L533,332.7z"/>
                            <path className="st20" d="M555.2,345.5c-0.4,0.2-1.2,0.1-1.8-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.4-1l10.7-6.2c0.4-0.2,1.2-0.1,1.8,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.4,1L555.2,345.5z"/>
                            <path className="st20" d="M577.4,358.3c-0.4,0.2-1.2,0.1-1.7-0.2l-16.1-9.3c-0.6-0.3-0.7-0.8-0.3-1l10.7-6.2c0.4-0.2,1.2-0.1,1.7,0.2
                                l16.1,9.3c0.6,0.3,0.7,0.8,0.3,1L577.4,358.3z"/>
                            <path className="st20" d="M590.3,369.6c-0.3-0.2-0.6-0.3-0.9-0.3c-0.3,0-0.6,0-0.8,0.1l-10.7,6.2c-0.2,0.1-0.3,0.3-0.2,0.5
                                c0.1,0.2,0.3,0.4,0.5,0.5l4.2,2.4l-14,8.1c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.2,0.3,0.4,0.5,0.5l10.9,6.3c0.3,0.2,0.6,0.3,0.9,0.3
                                c0.3,0,0.6,0,0.8-0.1l25.4-14.7c0.2-0.1,0.3-0.3,0.2-0.5c-0.1-0.2-0.3-0.4-0.5-0.5L590.3,369.6z"/>
                            <path className="st20" d="M358.4,279.4c0,0-0.1,0-0.2,0l-12.3-7.1c-0.1,0-0.1-0.1-0.1-0.1l0.8-0.5c0,0,0.1,0,0.2,0l12.3,7.1
                                c0.1,0,0.1,0.1,0.1,0.1L358.4,279.4z"/>
                        </g>
                        <polygon className="st0" points="475.8,70 449.8,226.5 604.1,313.9 630.6,158.4 		"/>
                        <polygon className="st28" points="533,204.3 470.2,169.9 484.4,84 547.2,118.5 		"/>
                        <g>
                            <g>
                                <g>
                                    <path className="st28" d="M516.2,182.9c1.2,0,1.2-1.8,0-1.8C515.1,181,515.1,182.9,516.2,182.9L516.2,182.9z"/>
                                </g>
                            </g>
                            <path className="st9" d="M487.8,166.8l-5.6-9.3l-1.2-4.7c0-0.1,0.1-0.3,0.1-0.4c0.1-0.4-0.4-0.7-0.5-0.3c-1.9,6.7-4.4,13.1-5.3,20.5
                                l11,6L487.8,166.8z"/>
                            <path className="st9" d="M529.1,197.4c0.4-3.4,0.6-7.2,0.7-10.9c0.1-2.3,0.2-4.6,0.3-6.9c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1l0,0.1
                                c-0.1-0.2-0.5-0.2-0.5,0.2c0,0.1,0,0.3,0,0.4l-2.2,2.4l-7.5,2.1l-2.1,11.5l10.9,5.9C528.7,200.5,528.9,198.8,529.1,197.4z"/>
                            <path className="st9" d="M516,155.1c0.1,0,0.3-0.2,0.1-0.5c-0.5-0.7-1-1.4-1.4-2.1c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.2,0.1-0.5,0.1-0.7
                                c0.2-1.6,0.5-3.2,0.7-4.9c0-0.3-0.4-0.6-0.4-0.2c0,0.1,0,0.2-0.1,0.4l0.2-1.8l-12.4-6.8c0,0.4,0,0.8,0,1.2
                                c-0.1-0.2-0.3-0.3-0.4,0c-0.2,1.6-0.4,3.2-0.7,4.8c0,0.1,0,0.4-0.1,0.6c0-0.1-0.4,0.2-0.5,0.2c-0.6,0.3-1.1,0.5-1.7,0.8
                                c-0.2,0.1,0,0.7,0.2,0.5c0.3-0.1-0.4,0.5,0,0.4c-0.6,0.4-0.2-0.1-0.2-0.1l7,13.6l10.3-4.4L516,155.1z"/>
                            <path className="st20" d="M528.2,168.3L528.2,168.3c-1-1.7-2.3-3-3.5-4.2c-1.2-1.2-2.8-2.9-4.3-4.5c-1.5-1.7-3.1-3.4-4.3-4.6
                                l-0.2-0.2l-0.1,0.2c-2.2,4.1-5.8,5.5-9.5,3.6c-3.8-2-6.8-7-7.6-13.1l0-0.3l-0.2,0c-1.4-0.2-2.9-0.2-4.4-0.2c-1.5,0-3,0.1-4.4-0.2
                                c-1.3-0.2-2.8-0.5-4.2,0c-2.8,0.9-8.5,11.6-9.5,17.5l0,0.2l8.7,8.4c-0.1,0.7-0.1,1.5-0.2,2.2c0,0,0,0,0,0.1l-0.4,4.1l0.2,0.1
                                l0,0.1l16.9,9.3l1,0.5l17.1,9.4l0,0l0.2,0.2l1.3-5.9l9.5,1.4l0-0.2C531.3,188,530.3,172,528.2,168.3z"/>
                            <path className="st20" d="M516,154.4c-3.5,3.1-6.4,3.9-9.1,2.5c0,0,0,0,0,0c-2.7-1.5-5.1-5.4-7.2-11.5l0,0l-3.1-0.3
                                c0,0.1,2.5,11.5,9.9,15.6c7.2,3.9,11.6-1,11.7-1.1l0.9-1.6L516,154.4z"/>
                            <path className="st29" d="M502.3,139.5c0,0,1.3,5.8,5.4,8.1c4.1,2.3,7.5-1,7.5-1L502.3,139.5z"/>
                            <g>
                                <path className="st9" d="M521.7,131.3c-0.3-0.1-0.4,0.4-0.1,0.5c2.2,0.8,0.8,5.4,0.3,6.8c0.4-1.3,1.2-3.4,1.1-4.9
                                    c0-0.5-0.5-1.4-0.7-1.9c-0.2-0.5-0.7,0.1-1.1,0.1l-3,9.4c0,0,0.1,0,0.1,0.1c-0.1,0.1,0,0.4,0.1,0.5c2.4,1.6,3.5-1.5,4.1-3.7
                                    C523.1,136.6,524.1,132.2,521.7,131.3z"/>
                                <path className="st9" d="M501.2,131.8c-0.2-0.1-0.3-0.2-0.5-0.2c0.2,0.1,0.4,0.2,0.6,0.2l0.1-11c-0.1-0.1-0.1-0.1-0.2-0.1
                                    c0,0,0,0,0.1,0c0.3,0.2,0.4-0.4,0.1-0.5c-2.3-1.6-2.7,3.1-2.7,5c-0.1,2.5,0,6.2,2.4,7.2C501.4,132.5,501.5,131.9,501.2,131.8z"
                                    />
                            </g>
                            <path className="st9" d="M521.7,121.7c-0.6-4-2.6-7-5.3-9c-0.8-0.5-1.6-0.9-2.4-1.4l0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
                                c-2.4-1.3-4.8-2.4-7-1.4c-2.1,0.9-3.4,3.5-4.2,6.2c-1,3.3-1.3,7-1.6,10.7c-0.2,2.7-0.7,5.6-0.3,8.5c0.6,3.8,2.5,6.5,4.8,9
                                c2.3,2.6,4.9,3.1,7.5,3c2.9-0.1,4.9-2.4,5.8-5.8c0.7-2.8,1.5-5.5,2.1-8.4C521.7,129.5,522.3,125.7,521.7,121.7z"/>
                            <g>
                                <path className="st30" d="M514.7,126.9c0.8,0.2,1.6,0.7,2.5,1c1,0.3,1.6,1.1,2.1,2.3c0.3,0.6,0.7,0.3,0.4-0.3c-0.5-1.1-1-2.5-1.9-3
                                    c-1-0.5-1.9-0.9-2.9-1.1C514.3,125.7,514.2,126.8,514.7,126.9L514.7,126.9z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st31" d="M519.4,131c-0.2-0.1-0.3-0.3-0.5-0.6c-0.5-1.2-1-1.7-1.8-2c-0.4-0.1-0.8-0.3-1.1-0.5
                                        c-0.5-0.2-0.9-0.4-1.3-0.5l0,0c-0.4-0.1-0.7-0.6-0.7-1.1c0-0.6,0.3-1.2,0.8-1.1c0.9,0.2,1.9,0.6,3,1.2c0.9,0.5,1.5,1.7,1.9,2.8
                                        c0.1,0.2,0.1,0.3,0.2,0.5c0.3,0.6,0.2,1.1-0.1,1.3C519.7,131.1,519.5,131.1,519.4,131z M514.7,126.4L514.7,126.4L514.7,126.4z"
                                        />
                                </g>
                            </g>
                            <g>
                                <path className="st30" d="M509,123.8c-0.8-0.6-1.6-1-2.4-1.7c-0.9-0.7-1.6-0.6-2.4-0.2c-0.4,0.2-0.7-0.4-0.2-0.6
                                    c0.8-0.4,1.6-1.1,2.5-0.6c1,0.5,1.9,1.1,2.8,2C509.6,123.1,509.4,124.1,509,123.8L509,123.8z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st31" d="M503.8,122.4c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.4-0.8
                                        c0.1-0.1,0.2-0.1,0.3-0.2c0.7-0.4,1.5-0.9,2.5-0.4c1.1,0.6,2,1.2,2.9,2c0.5,0.4,0.5,1.2,0.4,1.7c-0.2,0.5-0.6,0.6-0.9,0.3h0
                                        c-0.4-0.3-0.8-0.6-1.3-0.9c-0.3-0.2-0.7-0.5-1.1-0.7c-0.7-0.5-1.3-0.6-2.1-0.1C504.2,122.5,504,122.5,503.8,122.4z
                                        M503.9,121.4L503.9,121.4L503.9,121.4z M509.1,123.3L509.1,123.3L509.1,123.3L509.1,123.3z"/>
                                </g>
                            </g>
                            <path className="st31" d="M512.1,117.8L512.1,117.8c2.5,1.3,4.9,2.4,7.4,3.6c0.8,0.4,1.7,0.8,2.6,0.8c0.4,0,0.8,0,1-0.4
                                c0.3-0.5,0.3-1.4,0.2-2.1c-0.6-4.4-2.8-8.3-5.4-10.6s-5.5-3.3-8.4-3.6c-2.1-0.2-5,0.2-5.7,3.7c-0.5,2.7,2.5,4.8,3.8,5.8
                                C509,116.2,510.6,117,512.1,117.8z"/>
                            <path className="st31" d="M505.5,113.5L505.5,113.5c0-0.1,0.1-0.3,0-0.5c0-0.2-0.1-0.3-0.2-0.5c-0.3-0.4-0.6-0.9-0.9-1.3
                                c-0.2-0.2-0.3-0.5-0.6-0.6c-0.4-0.2-0.8,0-1.1,0.4c-0.8,0.9-1.2,2.3-1.5,3.7c-0.2,1.5-0.2,3-0.1,4.6c0,1.1-0.1,2.7,0.3,3.7
                                c0.5,0,1-1.3,1.3-1.8c0.4-0.8,0.8-1.6,1.2-2.4C504.6,117.2,505.1,115.4,505.5,113.5z"/>
                            <path className="st31" d="M520.6,121.8L520.6,121.8c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.1,0.8-0.2,1.2-0.2
                                c0.2,0,0.4-0.1,0.7,0c0.4,0.2,0.6,0.8,0.8,1.4c0.4,1.6,0.4,3.2,0.1,4.6c-0.3,1.4-0.7,2.7-1.3,4c-0.4,0.9-0.8,2.3-1.3,2.9
                                c-0.4-0.5-0.4-2.1-0.5-2.7c-0.1-1.1-0.2-2.1-0.2-3.2C520.3,125.9,520.4,123.8,520.6,121.8z"/>
                        </g>
                        <polygon className="st6" points="520,215.9 490,199.5 491.1,192.7 521.1,209.2 		"/>
                        <polygon className="st6" points="612.5,207.6 548.2,172.7 548.9,169.9 613.2,204.7 		"/>
                        <polygon className="st6" points="609.8,219.4 545.6,184.5 546.2,181.7 610.5,216.5 		"/>
                        <polygon className="st6" points="604.8,261.2 538.2,224.9 538.8,222.1 605.4,258.3 		"/>
                        <polygon className="st6" points="602.2,273 535.6,236.8 536.2,234 602.8,270.2 		"/>
                        <polygon className="st6" points="514.5,246.2 484.6,229.8 485.7,223 515.6,239.5 		"/>
                        <polygon className="st6" points="598,295.1 531.3,258.9 531.9,256.1 598.6,292.3 		"/>
                        <polygon className="st6" points="583.5,218.3 542.9,196.3 543.5,193.5 584.2,215.5 		"/>
                        <path className="st32" d="M500.6,93.6c1.7-13.9-5.9-31.9-17-40.4c-5.1-3.9-10.1-5-14.1-3.8l0,0l0,0c-0.5,0.2-1.1,0.4-1.6,0.6l-4.4,2.1
                            l-0.4,2.4c-1.4,2.2-2.4,5.1-2.8,8.6c-1.7,13.9,5.9,31.9,17,40.4c2.3,1.7,4.5,2.9,6.7,3.6l3,2.4c0,0,5.4-2.5,5.7-2.7l0.1,0l0,0
                            C496.9,104.8,499.8,100.3,500.6,93.6z"/>
                        
                            <ellipse transform="matrix(0.9213 -0.3889 0.3889 0.9213 5.6266 190.6763)" className="st26" cx="473.8" cy="81.4" rx="17.9" ry="31"/>
                        <g>
                            <path className="st11" d="M472,97l-5-2.9c-0.5-0.3-0.7-0.8-0.6-1.4l4.8-28.1c0.2-1.2,1.8-2,2.8-1.4l5,2.9c0.5,0.3,0.7,0.8,0.6,1.4
                                l-4.8,28.1C474.7,96.8,473.1,97.6,472,97z"/>
                            <path className="st11" d="M459,76.2l1-5.9c0.2-1.2,1.8-2,2.8-1.4l23.7,13.6c0.5,0.3,0.7,0.8,0.6,1.4l-1,5.9c-0.2,1.2-1.8,2-2.8,1.4
                                l-23.7-13.6C459.1,77.3,458.9,76.8,459,76.2z"/>
                        </g>
                        <g>
                            <g>
                                <g>
                                    <path className="st9" d="M50.1,260.2c0,11.3,1.3,22,5.8,32.5c2.4,5.5,5.1,11.3,8.3,16.4c2.2,3.5,5.2,6.4,9.4,7.1
                                        c8.7,1.3,14.7-7.8,18-14.5c5.1-10.4,7.9-22.1,8.2-33.7c0.2-7-10.8-7-10.9,0c-0.2,8.7-2.1,17.4-5.4,25.4
                                        c-1.4,3.3-3.1,6.7-5.4,9.5c-0.7,0.8-1.4,1.7-2.4,2.2c-0.5,0.3-1.1,0.1-0.5,0.3c-2.2-0.7-4.1-6.5-5.1-8.5
                                        c-1.9-3.6-3.8-7.3-5.3-11.1c-3.1-8.2-3.7-16.7-3.8-25.5C61,253.1,50,253.1,50.1,260.2L50.1,260.2z"/>
                                </g>
                            </g>
                            <path className="st1" d="M85.1,293.8l-28-12c-2.1-0.9-3.3-2.5-2.8-3.8l14.3-44.8c0.5-1.6,3.3-2.1,5.9-1l28,12
                                c2.1,0.9,3.3,2.5,2.8,3.8L91,292.8C90.4,294.4,87.7,294.9,85.1,293.8z"/>
                            <g>
                                <path className="st19" d="M58.5,277.6l29.1,11.8c0.6,0.2,1.4-0.2,1.6-0.9l11.9-39.4c0.1-0.3,0-0.6-0.3-0.7l-29.1-11.8
                                    c-0.6-0.2-1.4,0.2-1.6,0.8l-11.9,39.4C58.1,277.2,58.2,277.5,58.5,277.6z"/>
                                <g>
                                    <path className="st33" d="M78.7,263.7l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.1-0.2l-2.9-1.2l-0.1,0.4C77.9,262.8,78.2,263.5,78.7,263.7z
                                        "/>
                                    <path className="st33" d="M77.8,266.7l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.1-0.2l-2.9-1.2l-0.1,0.4C77,265.8,77.3,266.5,77.8,266.7z"
                                        />
                                    <path className="st33" d="M79.6,260.7l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.1-0.2l-2.9-1.2l-0.1,0.4C78.9,259.8,79.1,260.5,79.6,260.7z
                                        "/>
                                    <path className="st33" d="M80.6,257.6l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.1-0.2l-2.9-1.2l-0.1,0.4C79.8,256.7,80,257.4,80.6,257.6z"
                                        />
                                    <path className="st33" d="M81.5,254.6l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.1-0.2l-2.9-1.2l-0.1,0.4C80.7,253.7,81,254.4,81.5,254.6z"
                                        />
                                    <path className="st33" d="M82.3,251.9l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.5-1.5l-2.9-1.2l-0.6,1.8C81.5,251,81.8,251.6,82.3,251.9z"
                                        />
                                    <path className="st33" d="M76.7,270.1l1,0.4c0.8,0.3,1.6-0.2,1.9-1.2l0.1-0.2l-3.5-1.4l-0.2,0.5C75.7,268.9,76,269.8,76.7,270.1z"
                                        />
                                    <path className="st33" d="M75.6,273.3l1.1,0.5c0.8,0.3,1.8-0.2,2.1-1.3l0.1-0.2l-3.9-1.6l-0.2,0.6C74.5,272,74.9,273,75.6,273.3z"
                                        />
                                    <path className="st33" d="M74.6,276.7l1.1,0.5c0.8,0.3,1.8-0.2,2.1-1.3l0.1-0.2L74,274l-0.2,0.6C73.5,275.4,73.9,276.4,74.6,276.7z
                                        "/>
                                    <path className="st33" d="M73.4,280l1.4,0.6c1,0.4,2.2-0.3,2.5-1.6l0.1-0.3l-4.8-1.9l-0.2,0.7C72.1,278.5,72.6,279.6,73.4,280z"/>
                                    <g>
                                        <path className="st33" d="M80.9,245.7c-0.5,0-1.4-0.1-1.8-0.3c-2.4-1.2-2.9-2.8-4.9-3.7c-0.3-0.2-0.8-0.2-1.2,0.3
                                            c-0.4,0.4-0.5,1.1-0.4,1.6c0.1,0.4,0.3,0.7,0.6,0.9l0,0c1.2,0.9,2.7,1.3,4.1,1.6c0.7,0.2,1.5,0.3,2.1,0.7
                                            c0.4,0.2,0.7,0.9,1.2,0.8c0.3-0.1,0.5-0.4,0.6-0.8C81.5,246.1,81.3,245.7,80.9,245.7z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M87.7,248.4c0.4,0.4,1.1,0.9,1.5,1.1c2.5,0.9,3.8-0.1,5.8,0.6c0.4,0.1,0.7,0.4,0.8,1.1
                                            c0.1,0.6-0.2,1.2-0.5,1.5c-0.3,0.3-0.6,0.3-1,0.3l0,0c-1.4-0.1-2.8-1-4.1-1.7c-0.7-0.4-1.3-0.8-2-0.9
                                            c-0.4-0.1-1.1,0.2-1.4-0.3c-0.2-0.2-0.2-0.7-0.1-1C87,248.3,87.4,248.1,87.7,248.4z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M75.6,248.4c-2.7,1.2-4.6,3-5.8,6.1c-1.1,2.8-1.7,5.5-2.1,8.8c-0.2,1.5-0.1,3.1,0.2,4.6
                                            c0.3,1.4,1.1,2.8,2.8,2.2c0.7-0.2,1-1.2,1.4-1.8c1-1.6,1.8-2.8,3.3-3.6c0.3-0.2,0.5,0.4,0.2,0.5c-1.9,1-2.5,2.7-3.7,4.4
                                            c-0.5,0.7-1.3,1.6-2.3,1.5c-2.2-0.1-2.4-3.5-2.5-5.2c-0.3-3.9,0.2-8.3,1.6-11.9c1.3-3.3,3.7-4.9,6.5-6.2
                                            C75.7,247.7,75.9,248.2,75.6,248.4L75.6,248.4z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M78.6,253.1c-2.4-0.2-3.9-1.4-5.5-3.3c-0.2-0.3,0.5-1.1,0.7-0.8c1.2,1.9,2.6,3.3,4.8,3.6
                                            C79,252.6,78.9,253.2,78.6,253.1L78.6,253.1z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M77.8,255.8c-2.7-0.3-5.1-1.6-7.1-3.8c-0.2-0.3,0.6-1.3,0.8-0.9c1.1,1.9,3.7,3.9,6.3,4.2
                                            C78.1,255.3,78.1,255.8,77.8,255.8L77.8,255.8z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M76.9,258c-2.8,0.1-5.7-0.8-7.9-2.8c-0.3-0.2,0.1-1.2,0.4-0.9c2.2,1.9,4.9,3.3,7.6,3.2
                                            C77.2,257.4,77.2,258,76.9,258L76.9,258z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M76.8,260.7c-3,0.1-6.5-0.5-8.6-3.1c-0.2-0.3,0.2-1.4,0.4-1.1c2,2.5,5.4,3.8,8.2,3.7
                                            C77.1,260.1,77.1,260.7,76.8,260.7L76.8,260.7z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M76.3,263.1c-2.9,0.7-6.2,0.1-8.9-1.5c-0.3-0.2,0.1-1.5,0.4-1.3c2.5,1.5,5.6,2.9,8.3,2.2
                                            C76.6,262.5,76.7,263.1,76.3,263.1L76.3,263.1z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M75.7,265.2c-2.7,0.7-5.6,0.7-8.2-0.8c-0.3-0.2-0.2-1.4,0.1-1.2c2.4,1.5,5.3,2.1,7.9,1.4
                                            C75.9,264.6,76,265.2,75.7,265.2L75.7,265.2z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M73.3,267.5c-0.9,0.4-1.8,0.7-2.8,0.8c-1,0-2-0.4-2.9-0.9c-0.3-0.2-0.3-1.2,0-1c0.8,0.4,1.7,1.1,2.6,1.1
                                            c1,0,2-0.2,2.8-0.5C73.4,266.8,73.6,267.3,73.3,267.5L73.3,267.5z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M90.3,254.3c1.5,2.9,1.9,5.6,1.2,8.9c-0.7,2.9-1.6,5.6-3.1,8.4c-0.7,1.3-1.6,2.5-2.7,3.4
                                            c-0.9,0.9-2.4,1.3-3.4-0.3c-0.4-0.7-0.1-1.7-0.1-2.4c0.1-1.9,0.1-3.4-0.7-5.2c-0.1-0.3-0.6-0.1-0.5,0.2
                                            c0.9,2.1,0.5,3.9,0.6,6.1c0,1,0.2,2.2,0.9,2.8c1.7,1.5,3.8-1,4.8-2.2c2.4-2.8,4.3-6.6,5.2-10.4c0.8-3.5-0.2-6.5-1.7-9.6
                                            C90.6,253.8,90.2,254,90.3,254.3L90.3,254.3z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M85.4,255.9c2,1.5,3.8,1.7,6.1,1.4c0.3,0,0.2-1.2-0.1-1.1c-2,0.6-3.8,0.8-5.7-0.7
                                            C85.4,255.2,85.1,255.7,85.4,255.9L85.4,255.9z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M84.6,258.6c2.3,1.7,4.9,2.4,7.6,2.1c0.3,0,0.2-1.4-0.1-1.3c-1.8,0.7-5,0.4-7.2-1.3
                                            C84.6,257.9,84.3,258.4,84.6,258.6L84.6,258.6z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M84.1,261c2.1,2.1,4.9,3.5,7.7,3.5c0.3,0,0.6-1,0.2-1c-2.7,0-5.6-1-7.6-3
                                            C84.2,260.3,83.9,260.7,84.1,261L84.1,261z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M82.7,263.1c2.3,2.2,5.3,4.3,8.4,3.8c0.3-0.1,0.6-1.2,0.3-1.2c-3,0.5-6.2-0.9-8.4-3.1
                                            C82.8,262.4,82.5,262.9,82.7,263.1L82.7,263.1z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M81.8,265.3c1.9,2.6,4.8,4.6,7.7,5.2c0.3,0.1,0.7-1.2,0.4-1.3c-2.8-0.6-5.9-1.8-7.7-4.3
                                            C82,264.7,81.6,265,81.8,265.3L81.8,265.3z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M81.1,267.4c1.8,2.5,4,4.6,6.8,5.3c0.3,0.1,0.9-1,0.5-1c-2.7-0.6-5.3-2.2-7-4.6
                                            C81.3,266.8,80.9,267.2,81.1,267.4L81.1,267.4z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M81.8,270.9c0.5,0.9,1,1.9,1.8,2.6c0.8,0.8,1.8,1.1,2.8,1.4c0.3,0.1,0.8-0.7,0.5-0.8
                                            c-0.9-0.3-2-0.4-2.7-1c-0.8-0.7-1.4-1.5-1.9-2.4C82.1,270.4,81.6,270.6,81.8,270.9L81.8,270.9z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M70.1,247.9c-0.1-0.1-0.2-0.3-0.2-0.6c0-0.3,0.1-0.9,0.3-1.4c0.4-0.8,0.7-0.9,1-0.9l0,0
                                            c0.6,0.1,1.3,0.2,1.9,0.3c0.2,0,1.3,0.1,0.4,1.8c-0.5,0.9-1.6,1-1.9,1.1C71,248.4,70.5,248.3,70.1,247.9z"/>
                                    </g>
                                    <g>
                                        <path className="st33" d="M95.3,258.1c0.2,0,0.3-0.1,0.5-0.3c0.2-0.3,0.4-0.8,0.5-1.3c0.2-0.9-0.1-1.2-0.3-1.4l0,0
                                            c-0.6-0.4-1.1-0.8-1.7-1.1c-0.2-0.1-1-0.9-1.3,1.1c-0.2,1.1,0.7,1.9,0.9,2.2C94.3,257.8,94.7,258.1,95.3,258.1z"/>
                                    </g>
                                    <path className="st33" d="M80.5,252.9l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C80.4,252.8,80.4,252.9,80.5,252.9z"/>
                                    <path className="st33" d="M83.9,246.7l0.8,0.3c0.6,0.3,1.3-0.2,1.6-1l0.1-0.2l-2.9-1.2l-0.1,0.4C83.1,245.8,83.3,246.5,83.9,246.7z
                                        "/>
                                    <path className="st33" d="M82.9,245l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C82.8,244.9,82.8,245,82.9,245z"/>
                                    <path className="st33" d="M79.6,256l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C79.5,255.8,79.5,255.9,79.6,256z"/>
                                    <path className="st33" d="M78.7,259l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C78.5,258.8,78.6,259,78.7,259z"/>
                                    <path className="st33" d="M77.8,262l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C77.6,261.9,77.7,262,77.8,262z"/>
                                    <path className="st33" d="M76.9,265.1l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C76.7,264.9,76.8,265,76.9,265.1z"/>
                                    <path className="st33" d="M75.9,268.1l3.6,1.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-3.6-1.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C75.8,267.9,75.8,268,75.9,268.1z"/>
                                    <path className="st33" d="M74.7,271l4.2,1.7c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-4.2-1.7c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C74.6,270.8,74.6,271,74.7,271z"/>
                                    <path className="st33" d="M73.3,273.8l5.1,2.1c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-5.1-2.1c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C73.2,273.7,73.2,273.8,73.3,273.8z"/>
                                    <path className="st33" d="M72,276.7l6,2.5c0.1,0,0.2,0,0.2-0.2l0,0c0-0.1,0-0.3-0.1-0.3l-6-2.5c-0.1,0-0.2,0-0.2,0.2l0,0
                                        C71.9,276.5,71.9,276.7,72,276.7z"/>
                                    <path className="st33" d="M81.5,248.2c-0.2,0.7,0.1,1.4,0.7,1.7c0.6,0.2,1.3-0.1,1.5-0.8c0.2-0.7-0.1-1.4-0.7-1.7
                                        C82.4,247.1,81.7,247.5,81.5,248.2z"/>
                                    <path className="st33" d="M83.6,249c-0.2,0.7,0.1,1.4,0.7,1.7c0.6,0.2,1.3-0.1,1.5-0.8c0.2-0.7-0.1-1.4-0.7-1.7
                                        C84.5,248,83.8,248.3,83.6,249z"/>
                                </g>
                            </g>
                            <path className="st3" d="M72.5,474.6c-17.5,7.5-43.3,9.5-60-0.1c-16.6-9.6-16.7-25.1-0.2-34.6c16.5-9.5,48.3-15.1,60,0.1
                                C84.9,456.4,87,468.4,72.5,474.6z"/>
                            <g>
                                <g>
                                    <path className="st9" d="M32.4,307.2c-3.9-2.9-13.6-6.8-13.1-12.4c0.5-5.4,0.9-10.8,1.4-16.1c0.6-7-10.3-7-10.9,0
                                        c-0.3,3.5-0.6,6.9-0.9,10.4c-0.3,3.1-1,6.4-0.5,9.5c1.6,8.8,12,13.3,18.4,18C32.6,320.9,38,311.4,32.4,307.2L32.4,307.2z"/>
                                </g>
                            </g>
                            <g>
                                <path className="st34" d="M13,247.9c-3.1,14.6-5.7,28.9-6.2,43.8c-0.3,8.3,14.3,8.2,14.6,0c0.5-13.8,3-27,5.9-40.4
                                    C29,243.3,14.7,239.8,13,247.9L13,247.9z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st35" d="M23.6,328c-2.9,39.6-2.4,79.2-2.6,118.9c0,9.4,14.5,9.4,14.6,0c0.2-39.7-0.3-79.3,2.6-118.9
                                        C38.9,318.6,24.3,318.6,23.6,328L23.6,328z"/>
                                </g>
                            </g>
                            <g>
                                <path className="st4" d="M25.8,454.9l2.3,7.1c0.8,1.2,3.4,3.2,4.8,2.6c5.4-2.5,20-7.7,17.5-14.1c-3.1-8.1-23.7,1.4-23.7,1.4
                                    L25.8,454.9z"/>
                                <path className="st35" d="M31.1,447.7c0,0,8.2-1.1,12,1.7c1.5,1.1,0.1,2.8-2.6,4c-5.3,2.5-11.4,1.8-14.4-2.3l-1.9-1.1L31.1,447.7z"
                                    />
                                <path className="st4" d="M18.7,449.1l2,16.9c0.1,0.5,0.4,1,0.8,1.4l0,0c1.3,0.9,3,0.1,3.1-1.5l0.7-14c0-0.5-0.2-1.1-0.5-1.5
                                    l-2.7-2.8C20.8,446.1,18.5,447.2,18.7,449.1z"/>
                                <path className="st4" d="M20.7,444.9c0,0-4.9,3.6-0.5,14.1c0.1,0.1,0.1,0.6,0.2,0.4c4.4-5.8,7.7,0,7.7,0l12.3-5.2
                                    c0,0-8,2.1-14.6-4.7C24,447.7,20.7,444.9,20.7,444.9z"/>
                            </g>
                            <path className="st4" d="M42.8,464.6l2.3,7.1c0.8,1.2,3.4,3.2,4.8,2.6c5.4-2.5,20-7.7,17.5-14.1c-3.1-8.1-23.7,1.4-23.7,1.4
                                L42.8,464.6z"/>
                            <path className="st9" d="M48.1,457.5c0,0,8.2-1.1,12,1.7c1.5,1.1,0.1,2.8-2.6,4c-5.3,2.5-11.4,1.8-14.4-2.3l-1.9-1.1L48.1,457.5z"/>
                            <g>
                                <g>
                                    <path className="st9" d="M45.6,338.7c0.4,24.9,0.1,49.9-2.1,74.8c-1.3,14.6-3.6,28.6-6,43.1c-1.6,9.2,12.5,13.1,14.1,3.9
                                        c2.6-15.2,5-30.5,6.5-45.9c2.3-25.2,2.6-50.6,2.2-75.9C60,329.3,45.5,329.3,45.6,338.7L45.6,338.7z"/>
                                </g>
                            </g>
                            <path className="st8" d="M56,349.7C45.6,369.6,36.6,360,24.3,351c-12.3-9.1-13.5-27-7-42c0,0,18.3-21.3,30.6-12.3
                                C60.2,305.8,68.2,326.6,56,349.7z"/>
                            <path className="st8" d="M11.5,264.4c0,0,7.9,40.8,8.8,44.4c0,0,4.4,12.5,35.6,0.7l8.6-38.7L11.5,264.4z"/>
                            <path className="st8" d="M61.6,280.4c1.2-0.7,1.5-2.7,1.5-2.7c0.8-7.1,4.8-10.4-3-22.6c-1.2-2-18.3-10.8-31.1-13.1
                                c-6.2-1.1-12.3,2.3-14.8,8.1c-1.5,3.4-2.9,10.1-2.9,10.1c-0.6,13.1,4.4,4.1,5.3,11.3c0-0.2-2.1-0.8-2.1-1c0,0,4.4,4.5,5.6,14
                                c0.7,5.9,1.3,10.2,1.3,10.8c0,0,0,0.1,0,0.1l22.2,17.5l11.7-21.1l0,0L61.6,280.4z"/>
                            <path className="st8" d="M16.7,329c0,0,1.4,61.8,1.9,62.7c3.3,5.3,4.9,15.9,37.9,8.2c3.8-0.9,5.3-5,5.1-8.3l0.5-63.6l-15.8,6.8
                                L16.7,329z"/>
                            <path className="st9" d="M32.1,245.5l4.4-19.8l12.1,8.1l-3.5,15.5C44.8,252.3,31.2,252.3,32.1,245.5z"/>
                            <path className="st9" d="M41.9,198.5c0,0-11.6-11.5-13.6,7.2c-1.4,13,6.1,25.1,17.2,31.4c6.9,3.9,16.2,0.8,17.2-5.7
                                c2.7-9.2,3.6-18.1,0.9-26c1.3-7.7-4.9-5.6-15-7.1L41.9,198.5z"/>
                            <path className="st31" d="M30.7,235.3c0,0-13.4-6.5-10.2-26c3.5-21.1,17.1-17.6,28.1-17.6c4.8,0,13.4,4.1,15.5,8.8
                                c2.5,5.5,2,8.9-4.5,8.8c-2.6,0-2.1,7-2.8,9.3c-1.3,4.1-4.1,5.9-5.5,9.9c-1.3,3.7-7.5,9.2-11.2,9.3
                                C33.4,237.9,30.7,235.3,30.7,235.3z"/>
                            <path className="st9" d="M54.9,224.6c0,3.4-2,6.1-4.5,6.1c-0.3,0-0.5,0-0.8-0.1c-2-0.5-3.6-3.1-3.6-6.1c0-3.4,2-6.1,4.5-6.1
                                C53,218.5,55,221.3,54.9,224.6z"/>
                            <g>
                                <g>
                                    <path className="st0" d="M28.4,243.1c1.1,3.8,3.6,6.9,7.4,8.3c4.4,1.7,9.7,0.4,14.2-0.1c1.7-0.2,1.7-2.8,0-2.6
                                        c-7.2,0.7-16.4,2.8-19-6.3C30.5,240.7,27.9,241.4,28.4,243.1L28.4,243.1z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st31" d="M53.5,207.5c1.3,6.1,2.7,14.1-0.1,20c-1.2,2.5-3,4.9-3,7.8c0,2.9,2,4.9,2.9,7.6c0.7,2.2,4.2,1.3,3.5-1
                                        c-0.5-1.4-1.3-2.5-1.8-3.8c-1.4-3.5-0.5-4.6,0.9-7.4c1.2-2.4,2.1-4.2,2.4-6.8c0.8-5.7,0-11.8-1.2-17.4
                                        C56.5,204.2,53,205.2,53.5,207.5L53.5,207.5z"/>
                                </g>
                            </g>
                            <path className="st4" d="M35.8,458.8l2,16.9c0.1,0.5,0.4,1,0.8,1.4l0,0c1.3,0.9,3,0.1,3.1-1.5l0.7-14c0-0.5-0.2-1.1-0.5-1.5l-2.7-2.8
                                C37.8,455.8,35.5,456.9,35.8,458.8z"/>
                            <path className="st4" d="M37.7,454.6c0,0-4.9,3.6-0.5,14.1c0.1,0.1,0.1,0.6,0.2,0.4c4.4-5.8,7.7,0,7.7,0l12.3-5.2c0,0-8,2.1-14.6-4.7
                                C41,457.4,37.7,454.6,37.7,454.6z"/>
                            <ellipse className="st1" cx="31" cy="204.8" rx="7.9" ry="7.6"/>
                            <path className="st31" d="M27.3,202c3.9-0.8,7.7,1.8,8.6,5.7c1.3,5.7,2.3,16.8-4,29.8c-9.3,19,6.9,45.3-1.4,57c0,0-2-7.6-8.2-19.3
                                c-6.2-11.7-8.2-31.8-6.9-45.6c1.3-13.7,8.1-25.7,8.1-25.7C24.5,203,25.4,202.4,27.3,202z"/>
                            <g>
                                <path className="st0" d="M47.4,260.2c0,8.6,1,17.2,4.1,25.3c3.1,8,7.2,15.6,10.6,23.5c1.6,3.6,6.9,4.4,10,2.6
                                    c3.7-2.2,4.2-6.3,2.6-10c-2.9-6.7-6.5-13.2-9.1-20c-2.7-7-3.6-14-3.6-21.4C61.9,250.8,47.3,250.8,47.4,260.2L47.4,260.2z"/>
                            </g>
                        </g>
                        <path className="st1" d="M153.8,297.2c0.7,0.7,2.3,2.3,3.1,3.2c1.5,1.7,3.8,1.8,6.2,1.2c1.1-0.3,2.7-0.8,2.5-2.5l-0.5-4.4
                            C163.4,293.9,158.5,294.8,153.8,297.2z"/>
                        <g>
                            <polygon className="st2" points="764.7,379 728.5,372.7 742.4,312.7 778.7,319 			"/>
                            <g>
                                <path className="st9" d="M680.3,288.6c1.2,0.3,2.3,0.5,3.5,0.4c2.8-0.3,5.3-2.6,7.6-4.9c0.2-0.2,0.4-0.4,0.5-0.7
                                    c0.3-0.6,0-1.3-0.3-1.8c-1.8-3-5.3-3.7-8.1-5.1c-1.3-0.6-2.5-1.5-3.8-2.1c-2-1-4.2-1.6-6.4-2.2l0,0c-2.6-0.7-12.1-3-12.3,3.7
                                    c-0.1,2.9,2.6,5.2,4.3,6.5c2,1.6,4.3,2.8,6.7,3.7C674.7,287.3,677.5,288,680.3,288.6z"/>
                            </g>
                            {/* pointer */}
                            <path className="pointer" d="M684,284.2L684,284.2c-0.5,0.9-1.6,1.3-2.6,0.9l-62.7-31.4c-0.9-0.5-1.3-1.6-0.9-2.6l0,0
                                c0.5-0.9,1.6-1.3,2.6-0.9l62.7,31.4C684.1,282.1,684.5,283.3,684,284.2z"/>
                            <path className="st9" d="M690.2,280.1c-1.4-2-3.9-4.4-5.7-6.1c-1.4-1.3-3.1-2.1-5-2.4c-3.6-0.5-9.6-1-11.2,0.7
                                c-2,2.2,1.2,4.4,3.1,5.7c3.8,2.6,9.3,3.8,13.7,4.3c0.8,0.1,1.3-0.2,1.6-0.7c0.3,0.3,0.5,0.6,0.7,1
                                C688.8,284.5,691.5,282,690.2,280.1z"/>
                            <polygon className="st21" points="692.5,279 699.7,282.6 691.4,293.8 685.4,289.4 			"/>
                            <path className="st34" d="M698,280.4c3.8,3.2,10.4,7.7,19.1,10c3.4,0.9,6.8-1.6,6.8-5.1c0.2-15.7,0.5-40.6,6.4-51.7
                                c4.1-7.8,16.9-2.5,12.7,5.4c-3.9,7.3-6.4,14.8-7.4,23c-0.8,6.8-0.7,13.7-1,20.6c-0.3,6.3-1.1,12.6-3.1,18.6c-1.3,4-2.4,6.2-7,6.8
                                c-9.3,1.3-35-13.7-35.3-14.5L698,280.4z"/>
                            
                                <ellipse transform="matrix(3.854997e-02 -0.9993 0.9993 3.854997e-02 235.1621 1194.3287)" className="st3" cx="738.2" cy="475" rx="26.4" ry="45.8"/>
                            <path className="st36" d="M776.4,251.9l-29.3-14.4l4.2-12.3c7.7,0.2,21.1,8.5,27.3,13.2L776.4,251.9z"/>
                            <path className="st21" d="M772.4,486.5c0.2-0.4,2.9-5.6,3.6-8.7c0.1-0.6,0.6-3.4,0.5-4.3c0-2.1,0.4-3.4-4.3-6.5
                                c-7.6-5.1-16,20.8-16,20.8l-0.8,6.4c-0.2,1.1,0.6,2.4,1.6,3.1l0.6,0.2c3.7,1.1,6,0.3,7.7-1.5C768.1,493,769.3,492.6,772.4,486.5z
                                "/>
                            <path className="st17" d="M772.3,484.7c0.2-0.4,8-15.8,1.2-18.9c-6-2.7-9.3,3.2-12.1,9.5c-0.8,1.9-2.8,5.9-3.5,7.2
                                c-0.6,1-1.4,4.1-1.6,5.2l-0.2,5c-0.2,1.1,1.3,0.2,2.3,0.9l1.1-4.4c1.9,1.3,3.3,7,5,5.2C767.4,491.4,769.3,491,772.3,484.7z"/>
                            <path className="st17" d="M768.3,490.9c-0.8,0.8-2.5,2.4-3.5,3.3c-1.7,1.8-4.2,1.7-6.7,1c-1.2-0.4-2.9-1-2.6-2.8l0.7-4.7
                                C758.1,487,763.4,488.1,768.3,490.9z"/>
                            <g>
                                <g>
                                    <path className="st5" d="M767.1,330.2c-8.8,23.5-8.8,50.2-7.8,74.9c0.5,11.6,1.4,23.3,2.3,34.9c0.6,7.4,1.5,15,1.1,22.5
                                        c-0.5,9.3,13.9,9.2,14.4,0c0.4-7.5-0.5-15-1.1-22.5c-0.9-11.2-1.7-22.3-2.2-33.5c-1-24-1.3-49.6,7.2-72.5
                                        C784.3,325.3,770.4,321.6,767.1,330.2L767.1,330.2z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st5" d="M730.2,324.7c-4.4,13.7-6.8,27.9-8.9,42.1c-1.3,8.9-3.2,17.9-2.7,27c0.9,17.1,3.3,34.5,2.4,51.6
                                        c-0.5,9.3,14,9.3,14.4,0c0.9-16.8-1.5-34-2.4-50.8c-0.5-8.3,1.1-16.5,2.3-24.7c2-13.9,4.4-27.9,8.7-41.3
                                        C747,319.7,733.1,315.9,730.2,324.7L730.2,324.7z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="st28" d="M763.2,462.3c3.5,3.3,11,3,13.9-0.8c0,2.2-0.1,4.4-0.2,6.6c-0.5,8.9-14.4,8.5-13.9-0.4
                                        C763.1,465.9,763.1,464.1,763.2,462.3z"/>
                                </g>
                            </g>
                            <g>
                                <path className="st5" d="M735.5,353.3l1.2-0.2c1.5,1.8-1.5-4.9,0.5-3.6c4.1,2.7,20.8,15.8,25.7,16.1c5.9,0.4,8,3.8,12.5,0.1
                                    c4.3-3.6,6.4-16.3,4.7-21.1c-0.5-1.5-4.2-4.2-4.8-5.7c-0.6-1.6,5-1.1,3.7-2.1c-10.1-8.4-29.8-28.9-44.7-23.2
                                    C717.3,320,728.4,344.6,735.5,353.3z"/>
                            </g>
                            <path className="st21" d="M735.2,232.5c-3.2,3.8-2.9,6.2-3,11.5c-0.1,5.2,3.2,9.9,8.2,11.5l50.5,16.6c0,0,3.8-19.5-6-25.9
                                c-5.3-3.4-13.5-8.9-19.9-13.2C755.4,226.6,742.6,223.9,735.2,232.5z"/>
                            <path className="st21" d="M728.4,324.8c1.4,2,5.8-53,4.3-88.1l56.3,29.9c-1.4,26.3-10,46-8.3,78.2L728.4,324.8z"/>
                            <path className="st21" d="M766.1,347.8c-14-5.8-27.3-12.1-34.8-22.2c-3.8-2.4-3.1-6.4,1.5-8.8l0,0c4.6-2.4,11.5-2.4,15.3,0
                                C748.1,316.9,769.9,350.2,766.1,347.8z"/>
                            <path className="st25" d="M780.5,460.6l-0.1,6.1c0,0.3-0.1,0.5-0.2,0.7c-0.9,1.3-4.5,5.5-11.2,4.4c-5.8-1-9-2.8-10.2-3.7
                                c-0.4-0.3-0.6-0.7-0.5-1.2l0.5-6.3c0.1-0.9,1.1-1.5,1.9-1c3,1.7,9.6,4.3,17.9-0.3C779.5,459,780.6,459.6,780.5,460.6z"/>
                            <g>
                                <path className="st21" d="M719.5,467.7c0.4-0.1,4.2-3,4.6-3.1c0.6-0.2,6-2.6,6.8-3c1.8-0.9,6.2-1.3,4.2-7.1
                                    c-2.9-8.5-25.6,3.7-25.6,3.7l-7.7,5.4c-0.9,0.7-1.3,1.7-1,2.8l0,0c0.6,2.1,3.2,3.7,5.7,3.5C710.8,469.6,713,470.2,719.5,467.7z"
                                    />
                                <path className="st17" d="M719.2,465.6c0.4-0.1,4.2-3,4.6-3.1c0.6-0.2,7.1-2.5,8-2.9c1.8-0.9,6.1-1.9,4.1-7.7
                                    c-2.9-8.5-26.7,4.2-26.7,4.2l-7.7,5.4c-0.9,0.7-1.3,1.7-1,2.8l0,0c0.6,2.1,3.2,3.7,5.7,3.5C710.6,467.4,712.8,468,719.2,465.6z"
                                    />
                                <path className="st17" d="M710.5,467.7c-1.1,0-3.2,0-4.5,0.1c-2.5,0.2-4.9-1.4-5.4-3.5c-0.3-1.1,0.1-2.2,1-2.8l3.8-2.6
                                    C708.3,459.6,710,462.7,710.5,467.7z"/>
                            </g>
                            <g>
                                <g>
                                    <path className="st28" d="M722.1,443.3c3.5,2.9,10.9,2.9,13.9-0.5c0,1.9-0.1,3.9-0.3,5.8c-0.6,7.9-14.4,7.3-13.9-0.6
                                        C722,446.4,722,444.9,722.1,443.3z"/>
                                </g>
                            </g>
                            <path className="st25" d="M739.7,441.5l-0.4,6.1c0,0.3-0.1,0.5-0.3,0.7c-1,1.2-4.8,5.3-11.4,3.8c-5.8-1.3-8.8-3.3-10-4.2
                                c-0.4-0.3-0.5-0.7-0.5-1.2l0.8-6.2c0.1-0.9,1.2-1.4,2-0.9c2.9,1.9,9.4,4.8,17.9,0.6C738.7,439.9,739.7,440.5,739.7,441.5z"/>
                            <path className="st9" d="M753.9,237.1l-4.3-20.9l14.8,4.5l3.5,17.1C768.7,240.5,757.5,247.8,753.9,237.1z"/>
                            <path className="st37" d="M751.4,344.1c4.5,2.3,9.4,4.4,14.3,6.5c3.8,2.4,13.2-5.1,13.2-5.1c-1.7-32.2,8.7-52.6,10.1-78.8l-2-1
                                C784.7,296.1,777.6,344.1,751.4,344.1z"/>
                            <path className="st8" d="M778.5,237.9c0,0-17.6,17.5-22.8,60.9c-2.2,17.9-7.9,92.2-4,94.5c6.1,3.6,13.8,7.6,21.9,7
                                c7.5-0.5,7-9.3,7.3-12.5c-1.2-26.6,2.9-95,10.7-120.2l-2.1-16.9c-0.1-1.2-0.5-3.6-1.3-4.6L778.5,237.9z"/>
                            <path className="st8" d="M749.9,229.6c0,0-16.3,73.4-13.6,147.8c0.1,2.5-0.6,12-3.2,12.2c-5.9,0.7-17.1-9.7-17-12.1
                                c0.4-14,9.1-49.9,9.3-52.1c1.3-14.8,4.4-74.5,4.4-74.5s-1-26.2,19.5-24.5C773.7,228.5,749.9,229.6,749.9,229.6z"/>
                            <path className="st0" d="M739.9,228.1c-2.3,10-6.7,21.4-5.4,28.6l4.9-0.6l-3.2,8.8l3.4,35.1c0,0,0-36.6,11.7-74.8
                                C746.6,225,745.2,225.9,739.9,228.1z"/>
                            <g>
                                <path className="st12" d="M753.3,232.6c0,0,6.5-3.9,6.5-3.9c0,0-2.3,6.3-5.5,7.9L753.3,232.6z"/>
                                <path className="st9" d="M750.7,232l-4.4-0.8c-6.8-1.2-11.3-7.6-10.2-14.4l3.9-22.3c1.2-6.8,7.6-11.3,14.4-10.2l4.4,0.8
                                    c6.8,1.2,11.3,7.6,10.2,14.4l-2.3,16.4C765.5,222.7,757.5,233.1,750.7,232z"/>
                                <path className="st31" d="M756.5,201.8c-5,1.2-7.7,0.5-12.4-2.5c-5-3.3-4.5-10.5,0.6-13.6c2.8-1.7,3.2-2.9,8.6-3.9
                                    c15.2-2.8,29,7.6,26.2,23.7c0,0,0.1,21.8-13.4,22.4l-1.5-6.8c0,0,2.6-1,0.2-4.6c-0.6-1-1.6-2.1-3.1-3.5
                                    c-1.6-1.5-1.5-4.2-0.7-6.8C761.7,203.6,759.2,201.2,756.5,201.8z"/>
                                <path className="st9" d="M767.5,222.8c-2.3,1.2-4.4,0.2-4.7-2.3c-0.3-2.5,1.3-5.5,3.6-6.7c2.3-1.2,4.4-0.2,4.7,2.3
                                    C771.4,218.6,769.8,221.6,767.5,222.8z"/>
                                <path className="st14" d="M751.2,214.2c-0.2,0.9-0.7,1.6-1.2,1.5c-0.5-0.1-0.7-0.9-0.5-1.9c0.2-0.9,0.7-1.6,1.2-1.5
                                    C751.2,212.4,751.4,213.2,751.2,214.2z"/>
                                <path className="st14" d="M741.5,208.2c-0.2,0.9-0.7,1.6-1.2,1.5c-0.5-0.1-0.7-0.9-0.5-1.9c0.2-0.9,0.7-1.6,1.2-1.5
                                    C741.5,206.4,741.7,207.3,741.5,208.2z"/>
                                <g>
                                    <g>
                                        <path className="st38" d="M751.3,209.4c1.4,0.3,2.7,1.3,3.5,2.5c0.6,1,2.2,0.1,1.6-0.9c-1-1.6-2.7-2.9-4.6-3.3
                                            C750.6,207.3,750.1,209.1,751.3,209.4L751.3,209.4z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path className="st38" d="M740.5,203.2c1.3,0.2,3.3,0.7,4.2,1.8c0.7,1,2.3,0,1.6-0.9c-1.3-1.7-3.7-2.5-5.8-2.7
                                            C739.3,201.2,739.3,203,740.5,203.2L740.5,203.2z"/>
                                    </g>
                                </g>
                                <path className="st39" d="M737.9,208.2c-1.2,6.8,3.4,13.2,10.2,14.4l4.4,0.8c4.2,0.7,7.4-3.9,10.5-9.2l0,0c0.7,0.7,1.9,0.7,2.6,0
                                    l0.4-0.4l-1.5,5.2c-1.2,6.8-7.1,15.1-13.9,13.9l-4.4-0.8c-6.8-1.2-11.3-7.6-10.2-14.4l3.9-22.3c0.2-0.9,0.4-1.8,0.8-2.7
                                    L737.9,208.2z"/>
                                <path className="st0" d="M740.3,216.8l6.9,4.8c0.3,0.2,0.4,0.6,0.3,0.9c-0.4,1.2-1.8,3.5-5.6,1.2c-2.7-1.6-2.9-4.6-2.8-6.3
                                    C739.1,216.8,739.8,216.5,740.3,216.8z"/>
                            </g>
                            <polygon className="st21" points="790.2,336.1 782.4,340.5 776.5,331.7 784.8,327.2 			"/>
                            <g>
                                <path className="st0" d="M783.4,330.2c-1-1.4-0.8-3.4,0.6-4.5c5.6-4.7,8.8-11.4,9.7-19.9c2-18.5-6.8-34.7-15.6-50.2
                                    c-4.4-7.8,7.9-14.2,12.2-6.4c10.7,19,20.4,38.9,16.6,61.3c-1.4,8.5-4.5,16.4-10.3,22.8c-1.3,1.4-2.6,2.6-4,3.7
                                    c-1.5,1.2-3.7,0.8-4.8-0.7L783.4,330.2z"/>
                            </g>
                            <path className="st9" d="M776.6,344.6c0.4,0.4,8.8-2.6,5.7-9.6c-3-7.1-13.1-0.8-13.1-0.8S773.1,341.2,776.6,344.6z"/>
                            <g>
                                <g>
                                    <path className="st2" d="M765.8,326.9c0.6,4.5,2.5,8.4,4.7,12.3c2,3.6,4.8,5.9,7.8,8.6c1,0.9,2.4-0.6,1.5-1.5
                                        c-6.1-5.4-10.8-11.6-11.9-20C767.6,325,765.6,325.6,765.8,326.9L765.8,326.9z"/>
                                </g>
                            </g>
                            <path className="st0" d="M783.4,242.3l-10.8,23.3l-6.4-1.3l1.3,10.3l-12.4,28.6c0,0,1.6-23.7,10.2-49c2.3-6.7,6.6-15.4,5.4-21.7
                                C775.1,234.8,779.5,238.3,783.4,242.3z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </Container>
    );
}
 
export default HomeIllustration;