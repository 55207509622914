import React from "react"
import { Link } from 'gatsby'

import styled from 'styled-components'
import SEO from "../components/seo"

import HomeIllustration from '../components/illustrations/homeIllustration'

const Container = styled.section`
  min-height: 100vh;
  display: grid;
  grid-template: 1fr auto / 1fr;
  align-items: center;
  background-color: var(--alabaster);
  padding: 0 var(--gutter-s);
  @media (min-width: 768px) {
      grid-template: 1fr auto / 1fr 1fr;
      padding: 0 var(--gutter-l);
      grid-gap: 0 var(--gutter-l);
  }
`

const InfoBlock = styled.div`
  padding: var(--gutter-m) 0;
  h1, h4, p {
      color: var(--manatee);
  }
  h1 {
      font-size: 2rem;
      margin-bottom: 2rem;
  }
  p {
      font-size: 1rem;
      line-height: 150%;
      a {
        color: var(--pomegranate);
      }
  }
  @media (min-width: 768px) {
      padding: 0 0;
      h1 {
          font-size: 5rem;
          span {}
      }
  }
`

const Hero = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  padding: var(--gutter-m) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
      width: 60%;
  }
  @media (min-width: 768px) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      padding: var(--gutter-m) 0;
      img {
          width: 100%;
          max-width: 600px;
      }
  }
`

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Not found" />
      <InfoBlock>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
        <p>Please click <Link to="/">here</Link> to return home.</p>
      </InfoBlock>
      <Hero>
          <HomeIllustration />
      </Hero>
  </Container>
)

export default NotFoundPage
